import { Arrow, getColor } from "@mzt-monorepo/mzt-atomic";
import styled, { css } from "styled-components";

const svgStyle = css`
  width: 24px;
  height: 24px;
  margin: 0 auto;
  cursor: pointer;
`;

export const Container = styled.section`
  width: 400px;
  padding: 1rem 0;
  background-color: ${getColor("gray", 10)};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const Up = styled(Arrow)`
  ${svgStyle}
  transform: rotate(180deg);
`;

export const Down = styled(Arrow)`
  ${svgStyle}
`;

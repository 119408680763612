import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Briefcase = (props: Record<string, any>) => (
  <svg
    id="work-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path id="Path_1571" data-name="Path 1571" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_1572"
      data-name="Path 1572"
      d="M20,6H16V4a1.993,1.993,0,0,0-2-2H10A1.993,1.993,0,0,0,8,4V6H4A1.985,1.985,0,0,0,2.01,8L2,19a1.993,1.993,0,0,0,2,2H20a1.993,1.993,0,0,0,2-2V8A1.993,1.993,0,0,0,20,6ZM14,6H10V4h4Z"
      fill="#fa6f39"
    />
  </svg>
);

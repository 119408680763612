import { Avatar, useTableSelection } from "@mzt-monorepo/mzt-atomic";
import {
  ColumnSizes,
  TableColumn,
  TableData,
  Alignment,
  SortConfig,
} from "@mzt-monorepo/mzt-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getState } from "../../../redux/selectors/timesheets";
import TableName from "../../_atoms/TableName";
import TableViewButtons from "../../_atoms/TableViewButtons";
import TableActions from "../../_atoms/TableActions";
import TimesheetDocuments from "../../_atoms/TimesheetDocuments";
import TimesheetApprovalTable from "../../_molecules/TimesheetApprovalTable";

const COLUMNS: TableColumn[] = [
  {
    title: "",
    dataIndex: "icon",
    size: ColumnSizes.NO_GROW,
  },
  {
    title: "Name",
    dataIndex: "displayName",
    alignment: Alignment.LEFT,
    size: ColumnSizes.LARGE,
  },
  {
    title: "Role",
    dataIndex: "role",
    size: ColumnSizes.SMALLER,
  },
  {
    title: "Working Days",
    dataIndex: "workDays",
    size: ColumnSizes.SMALLER,
  },
  {
    title: "Leave Days",
    dataIndex: "leaveDays",
    size: ColumnSizes.SMALLER,
  },
  {
    title: "Reason",
    dataIndex: "reason",
    size: ColumnSizes.SMALLER,
  },
  {
    title: "Document",
    dataIndex: "document",
    size: ColumnSizes.SMALL,
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",
    size: ColumnSizes.SMALL,
  },
  {
    title: "Manager",
    dataIndex: "manager",
    size: ColumnSizes.MEDIUM,
  },
  {
    title: "Action",
    dataIndex: "action",
    size: ColumnSizes.SMALL,
  },
].map(
  (obj, index): TableColumn => ({
    key: index,
    ...obj,
  })
);

const buildTableRow = (user: MockTimesheetUser): TableData => ({
  ...user,
  icon: <Avatar src={user.picture} size="46px" shadow={false} />,
  displayName: <TableName name={user.displayName} email={user.email} />,
  action: (
    <TableActions
      acceptCallback={() => null}
      rejectCallback={() => null}
      deleteCallback={() => null}
      expandCallback={() => null}
    />
  ),
  document: (
    <TimesheetDocuments
      links={user.document}
      clickCallback={() => null}
      expandCallback={() => null}
    />
  ),
  key: user.ID,
});

const SORT_CONFIG: SortConfig = {
  directions: ["asc"],
  cols: ["role"],
};

export const TimesheetAprovalData = () => {
  const state = useSelector(getState);

  const ROWS: TableData[] = useMemo(
    () => state.list.map((value) => buildTableRow(value)),
    [state.list]
  );

  const selections = useTableSelection(ROWS);

  if (state.isLoading) {
    return <p>Loading</p>;
  }
  return (
    <>
      <TableViewButtons
        acceptCallback={() => null}
        disabled={false && !Object.values(selections.state).includes(true)}
      />
      <TimesheetApprovalTable
        rows={ROWS}
        columns={COLUMNS}
        selectMethods={selections}
        sortConfig={SORT_CONFIG}
      />
    </>
  );
};

export default TimesheetAprovalData;

import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { getColor } from "../../_static/colors";
import Check from "../../_static/iconography/check.svg";

interface ContainerProps {
  $isChecked: boolean;
}

const computeContainerProps = ({ $isChecked }: ContainerProps) => {
  if ($isChecked) {
    return `
      background-color: ${getColor("orange")};
      background-image: url(${Check});
      border: 1px solid ${getColor("orange")};
    `;
  }
  return null;
};

export const Container = styled.div<ContainerProps>`
  cursor: pointer;
  margin: 8px;
  height: 16px;
  width: 16px;
  border-radius: 6px;
  background-color: ${getColor("white")};
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid ${getColor("gray")};
  ${computeContainerProps}
`;

export const Wrapper = styled.input<InputHTMLAttributes<HTMLInputElement>>`
  height: 100%;
  width: 100%;
  background-color: transparent;
  appearance: none;
  cursor: inherit;
  outline: none;
  margin: 0;
`;

import styled, { css } from "styled-components";
import { getColor, Font } from "@mzt-monorepo/mzt-atomic";

const WHITE = getColor("white");
const BLACK = getColor("black");
const SEGEOUI = Font.SEGOEUI;

// container with all cards
export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

// container each card will be in
export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  border: 0.5px solid black;
  background-color: ${WHITE};
  width: 219px;
  height: 259px;
  background: ${WHITE} 0% 0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  margin: 0 1em 0 0;
`;

// top label with type off days
export const LabelTypeDays = styled.label`
  display: inline-block;
  height: 35px;
  line-height: 35px;
  font-family: ${SEGEOUI};
  font-size: 24px;
  font-color: ${BLACK};
  font-weight: bold;
  text-align: top;
  margin: 0.5em 0 0.5em 0;
  padding: 0;
  float: left;
  letter-spacing: 0px;
  opacity: 1;
`;

// label "Days" down the number of days
export const LabelDays = styled.label`
order=3;
position:absolute;
left: 92px;
top: 95px;
display: flex;
font-family: ${SEGEOUI};
font-size: 14px;
font-color: ${BLACK};
text-align: bottom;
margin: 0 0 0 0;
padding:0;
float:left;
letter-spacing: 0px;
opacity: 0.6;
`;

// Circle filled with color that depends on days type
export const Circle = styled.div<{ circleColor: string }>`
    order 2;
    width: 144px;
    height: 144px;
    line-height: 144px;
    border-radius: 50%;
    border-with: medium;
    font-size: 24px;
    font-family: ${SEGEOUI};
    font-weight: bold;
    text-align: center;
    text-color: ${WHITE};
    background: transparent;
    ${(props) =>
      props.circleColor &&
      css`
        border: 8px solid ${props.circleColor};
      `}
    opacity: 1;
`;

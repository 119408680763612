import React from 'react';
import { getColor } from '@mzt-monorepo/mzt-atomic';
import { Wrapper } from './styled-components';

interface ModalTitleProps {
  children: React.ReactChild;
  lineColor: string;
}

export const ModalTitle = ({
  children,
  lineColor = getColor('darkBlue'),
}: ModalTitleProps) => <Wrapper lineColor={lineColor}>{children}</Wrapper>;

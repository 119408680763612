import { Loading } from "@mzt-monorepo/mzt-atomic";
import { getState } from "./../../../redux/selectors/users";
import React from "react";
import { useSelector } from "react-redux";
import EmployeeCard from "../../_molecules/EmployeeCard";
import { Container, LoadingContainer } from "./styled-components";

const renderList = (users: MockUser[]) =>
  users.map((user: MockUser, index: number) => (
    <EmployeeCard user={user} key={index} />
  ));

const EmployeeList = () => {
  const state = useSelector(getState);

  if (state.isLoading)
    return (
      <LoadingContainer>
        <Loading style={{ height: "24px", width: "24px" }} />
      </LoadingContainer>
    );

  return <Container>{renderList(state.list)}</Container>;
};

export default EmployeeList;

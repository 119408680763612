import React from "react";
import { Logo } from "../../_atoms/logo";
import { ColorTypes } from "../../_static/colors";
import { Container, Label } from "./styled-components";

export type Position = "left" | "right" | "bottom" | "top";
export type Size = 24 | 32 | 40 | 48 | 56 | 64 | 80;

export interface LabeledLogoProps {
  label?: string | React.ReactNode;
  position?: Position;
  size?: Size;
  color?: ColorTypes;
}

export const LabeledLogo = ({
  label = "MOZANTECH",
  position = "right",
  size = 40,
  color = "black",
}: LabeledLogoProps) => (
  <Container position={position} size={size}>
    <Logo />
    <Label size={size} color={color}>
      {label}
    </Label>
  </Container>
);

export default LabeledLogo;

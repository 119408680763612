import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div`
  color: ${getColor("darkBlue")};
  border-bottom: 1px solid ${getColor("orange")};
  width: 100%;
  max-width: 232px;
`;

export const Title = styled.h1`
  margin: 0;
  color: ${getColor("darkBlue", 30)};
  font-size: 40px;
  font-weight: normal;
`;

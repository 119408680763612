import React from "react";
import { Wrapper } from "./styled-components";
import Default from "../../_static/iconography/groups.svg";

/* eslint-disable-next-line */
export interface AvatarProps {
  src?: string;
  size?: string;
  shadow?: boolean;
}

export const Avatar = ({
  src = Default,
  size = "104px",
  shadow = true,
}: AvatarProps) => <Wrapper src={src} size={size} shadow={shadow} />;

export default Avatar;

import { LabelSizes } from "@mzt-monorepo/mzt-types";
import React from "react";
import { Wrapper } from "./styled-components";

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  opaque?: boolean;
  size?: LabelSizes;
}

export const Label = React.memo(
  ({
    opaque = false,
    size = LabelSizes.NORMAL,
    children,
    ...props
  }: LabelProps) => (
    <Wrapper opaque={opaque} size={size} {...props}>
      {children}
    </Wrapper>
  )
);

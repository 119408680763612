export const USER: MockUser = {
  displayName: "Tiago Ferreira",
  fullName: "Tiago Miguel Nunes Ferreira",
  department: "IT",
  email: "tiago.ferreira@mozantech.com",
  picture:
    "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
  birthDate: "07/11/1992",
  employeeNo: 2,
  experienceYears: 2,
  phoneNumber: "+351 913 816 912",
  position: "Senior FE Developer && Advisor",
  role: "Consultant",
  startDate: "06/05/2020",
};

const RESPONSE: MockResponse<MockUser> = {
  success: true,
  data: USER,
};

export const login: (
  email: string,
  password: string
) => Promise<MockResponse<MockUser>> = (email: string, password: string) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(RESPONSE);
    }, 3000);
  });

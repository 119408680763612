import React from "react";
import { TagVariant, Wrapper } from "./styled-components";

export interface TagProps {
  children: React.ReactChild;
  variant?: TagVariant;
  className?: string;
  light?: boolean;
}

export const Tag = ({
  children,
  variant = "primary",
  className,
  light = false,
}: TagProps) => (
  <Wrapper $variant={variant} className={className} $light={light}>
    {children}
  </Wrapper>
);

import styled from "styled-components";
import { CrossThin } from "../../_static/icons";

import Card from "../card";

export const Overlay = styled.div`
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(Card)`
  min-width: 240px;
  min-height: 240px;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 4px;
`;

export const HeaderClose = styled(CrossThin)`
  cursor: pointer;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  padding: 8px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const Header = styled.div`
  padding: 4px;
  margin: 16px;
  display: flex;
  align-items: center;
`;

export const HeaderContent = styled.div`
  flex-grow: 1;
`;

import styled from "styled-components";
import { getColor } from "../../_static/colors";
import { CardVariant } from ".";

interface StyleProps {
  variant: CardVariant;
}

const getVariantColor = (variant: CardVariant) => {
  switch (variant) {
    case "primary":
      return getColor("white");
    case "secondary":
      return getColor("darkBlue");
    case "alternative":
      return getColor("purple");
    default:
      return getColor("white");
  }
};

export const Wrapper = styled.div<StyleProps>`
  border-radius: 13px;
  min-height: 56px;
  min-width: 24px;
  box-shadow: 0 0 5px -2px ${getColor("gray")};
  padding: 8px;
  background-color: ${({ variant }) => getVariantColor(variant)};
  color: ${({ variant }) =>
    variant !== "primary" ? getColor("white") : "inherit"};
  box-sizing: border-box;
`;

import { LabelSizes } from "@mzt-monorepo/mzt-types";
import styled, { css } from "styled-components";
import { getColor } from "../../_static/colors";

interface StyleProps {
  opaque: boolean;
  size: LabelSizes;
}

const computeRules = ({ opaque, size }: StyleProps) => css`
  opacity: ${opaque ? 0.5 : 1};
  font-size: ${size};
`;

export const Wrapper = styled.label<StyleProps>`
  color: ${getColor("darkBlue")};
  ${computeRules}
`;

import React, { useCallback, useState } from "react";
import {
  Header,
  HeaderClose,
  HeaderContent,
  Overlay,
  Content,
  Container,
} from "./styled-components";

export type ModalVariant = "primary" | "secondary" | "alternative";

/* eslint-disable-next-line */
export interface ModalProps {
  children?: React.ReactChild;
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  variant?: ModalVariant;
  header?: React.ReactNode;
}

export const Modal = ({
  children = "",
  isVisible,
  setVisible,
  variant = "primary",
  header = null,
}: ModalProps) => {
  const closeModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  return isVisible ? (
    <Overlay onClick={closeModal}>
      <Container
        variant={variant}
        onClick={(event) => event.stopPropagation()}
        role="dialog"
        aria-modal="true"
      >
        <Header>
          <HeaderContent aria-label="Modal header">{header}</HeaderContent>
          <HeaderClose
            onClick={closeModal}
            role="button"
            aria-label="Close modal"
          />
        </Header>
        <Content aria-label="Modal content">{children}</Content>
      </Container>
    </Overlay>
  ) : null;
};

export default Modal;

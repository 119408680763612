import {
  TableColumn,
  TableData,
  UseTableSelectionReturn,
} from "@mzt-monorepo/mzt-types";
import React from "react";
import { Wrapper } from "./styled-components";
import Row from "../row";

interface TableBodyProps {
  data: TableData[];
  columns: TableColumn[];
  selectable: UseTableSelectionReturn;
  handleSelectSingleRow: (
    event: React.ChangeEvent<HTMLInputElement>,
    row: TableData
  ) => void;
}

export const TableBody = ({
  data,
  columns,
  selectable,
  handleSelectSingleRow,
}: TableBodyProps) => {
  return (
    <Wrapper>
      {data.map((row: TableData, index: number) => (
        <Row
          columns={columns}
          data={row}
          handleSelectSingleRow={handleSelectSingleRow}
          index={index}
          selectable={selectable}
          key={row.key}
        />
      ))}
    </Wrapper>
  );
};

import moment, { Moment } from "moment";

export const getMonthMinMax = (month: Moment) => ({
  min: month.startOf("month").format("YYYY-MM-DD"),
  max: month.endOf("month").format("YYYY-MM-DD"),
});

export const cloneMoment = (
  value: Moment,
  offset?: number,
  offsetUnit?: moment.unitOfTime.DurationConstructor
) => (value || moment()).clone().add(offset, offsetUnit);

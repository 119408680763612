import { Button, getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 272px;
  padding: 16px;
`;

export const Row = styled.div`
  margin: 8px 0;
  display: flex;
  color: ${getColor("darkBlue", 30)};
  width: 100%;
  max-width: 232px;

  b {
    margin-left: 8px;
    color: ${getColor("darkBlue")};
  }
`;

export const Submit = styled(Button)`
  margin: 32px auto;
  font-size: 10px;
  font-weight: normal;
  height: 24px;
  width: auto;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

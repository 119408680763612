export type Device =
  | "mobileS"
  | "mobileM"
  | "mobileL"
  | "tablet"
  | "laptop"
  | "laptopL"
  | "desktop";

export type Breakpoint = 320 | 375 | 425 | 768 | 1024 | 1440 | 2560;

export type MediaQueryRule = "max-width" | "min-width";

export enum Roundness {
  FLAT = "flat",
  SEMI_ROUND = "semi-round",
  ROUND = "round",
}

export interface ColorScheme {
  background: string;
  secondary: string;
  text: string;
}

import { Anchor } from "@mzt-monorepo/mzt-atomic";
import React from "react";
import { Container } from "./styled-components";

export const LoginRecovery = () => (
  <Container>
    Login not working?{" "}
    <span>
      <Anchor>Get in touch</Anchor>
    </span>
  </Container>
);

export default LoginRecovery;

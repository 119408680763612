import styled, { css } from "styled-components";
import { getColor } from "../../_static/colors";
import { Caret } from "../../_static/icons/Caret";

interface DaysOfMonthProps {
  $isCurrent: boolean;
  $isDisabled: boolean;
  $mini: boolean;
}

interface MiniProps {
  $mini: boolean;
}

const svgStyle = css`
  width: 16px;
  height: 16px;
  margin: 8px;
  cursor: pointer;
  fill: ${getColor("darkBlue")};
  transition: fill 0.2s ease-in-out;

  &:active {
    fill: ${getColor("orange")};
  }
`;

export const Wrapper = styled.div`
  color: ${getColor("darkBlue")};
`;

export const Header = styled.div<MiniProps>`
  display: flex;
  align-items: center;
  font-size: 32px;
  margin: ${({ $mini }) => ($mini ? "0.5rem 0" : "0.5rem 1rem")};
  font-size: ${({ $mini }) => ($mini ? "10px" : "24px")};
`;

export const Month = styled.div`
  flex-grow: 1;
`;

export const MonthCycle = styled.div<MiniProps>`
  svg {
    height: ${({ $mini }) => $mini && "16px"};
    width: ${({ $mini }) => $mini && "16px"};
  }
`;

export const Left = styled(Caret)`
  ${svgStyle};
  transform: rotate(90deg);
`;

export const Right = styled(Caret)`
  ${svgStyle};
  transform: rotate(-90deg);
`;

export const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const Day = styled.div`
  height: 64px;
  width: 64px;
  justify-self: center;
  margin: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 20px;
  overflow: hidden;
`;

export const DayOfWeek = styled(Day)<MiniProps>`
  color: ${getColor("darkBlue")};
  font-size: ${({ $mini }) => ($mini ? "8px" : "16px")};
  height: ${({ $mini }) => $mini && "16px"};
  width: ${({ $mini }) => $mini && "16px"};
`;

export const DayOfMonth = styled(Day)<DaysOfMonthProps>`
  opacity: ${({ $isDisabled }) => ($isDisabled ? "0.2" : "0.98")};
  border-radius: 20px;
  position: relative;
  color: ${({ $isCurrent }) => $isCurrent && getColor("white")};
  height: ${({ $mini }) => $mini && "16px"};
  width: ${({ $mini }) => $mini && "16px"};

  span {
    height: ${({ $mini }) => ($mini ? "12px" : "32px")};
    width: ${({ $mini }) => ($mini ? "12px" : "32px")};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ $isCurrent }) => $isCurrent && getColor("orange")};
    color: ${({ $isCurrent }) => $isCurrent && getColor("white")};
    border-radius: 48px;
    position: relative;
    font-size: ${({ $mini }) => $mini && "8px"};
  }
`;

import { Exit, getColor } from "@mzt-monorepo/mzt-atomic";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const baseIconStyle = css`
  cursor: pointer;
  height: 20px;
  width: 20px;
  padding: 8px 16px;
  fill: ${getColor("white")};
  transition: fill 0.2s ease-in-out;

  &:active {
    fill: ${getColor("orange")};
  }
`;

export const Container = styled.section`
  min-width: 234px;
  background-color: ${getColor("darkBlue")};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 15px 0px ${getColor("black")};
`;

export const Header = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${getColor("white")};

  h2 {
    font-weight: normal;
    margin: 16px;
  }
`;

export const Footer = styled.div`
  padding: 24px 0;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Logout = styled(Exit)`
  ${baseIconStyle};
`;

export const SettingsRedirect = styled(Link)`
  svg {
    ${baseIconStyle}
  }
  padding: 0;
`;

import styled from "styled-components";
import { Position, Size } from ".";
import { Font } from "../../_static/fonts";
import { ColorTypes, getColor } from "./../../_static/colors/index";

interface BaseProps {
  size: Size;
}

interface ContainerProps extends BaseProps {
  position: Position;
}

interface LabelProps extends BaseProps {
  color: ColorTypes;
}

const getFlexDirection = (position: Position) => {
  switch (position) {
    case "bottom":
      return "column";
    case "top":
      return "column-reverse";
    case "left":
      return "row-reverse";
    default:
      return "row";
  }
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${({ position }) => getFlexDirection(position)};
  align-items: center;

  img {
    height: ${({ size }) => `${size}px`};
  }
`;

export const Label = styled.label<LabelProps>`
  ${Font.MONUMENT}
  font-size: ${({ size }) => `${(2 * size) / 3}px`};
  margin: 8px;
  color: ${({ color }) => getColor(color)};
`;

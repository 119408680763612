import styled from "styled-components";
import { Label } from "../../_atoms/label";

export const Container = styled.div``;

export const Wrapper = styled.div`
  min-width: 320px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const SelectLabel = styled(Label)`
  display: block;
  box-sizing: border-box;
  margin-bottom: 8px;
`;

import { Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import { Wrapper } from "./styled-components";

export type ButtonVariant = "primary" | "secondary";
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  isHollow?: boolean;
  roundness?: Roundness;
}

export const Button = ({
  children = "",
  variant = "primary",
  isHollow = false,
  roundness = Roundness.FLAT,
  ...props
}: ButtonProps) => (
  <Wrapper
    {...props}
    variant={variant}
    isHollow={isHollow}
    roundness={roundness}
  >
    {children}
  </Wrapper>
);

export default Button;

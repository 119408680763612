import React from "react";
import LoginHOC from "../../_components/login";
import { Container } from "./styled-components";

const Login = () => (
  <Container>
    <LoginHOC />
  </Container>
);

export default Login;

import moment from "moment";

const TIMESHEETS: MockTimesheets[] = [
  {
    month: moment("12/2020", "MM/YYYY"),
    project: "IFCHOR",
    status: "Approved",
    limitDate: "15/12",
    manager: "PEDRO PINTO",
    cardDays: [21, 2, 3],
  },
  {
    month: moment("01/2021", "MM/YYYY"),
    project: "IFCHOR",
    status: "Not Submited",
    limitDate: "15/01",
    manager: "PEDRO PINTO",
    cardDays: [8, 1, 2],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
];

const RESPONSE: MockResponse<MockTimesheets[]> = {
  success: true,
  data: TIMESHEETS,
};

export const getTimesheets: () => Promise<
  MockResponse<MockTimesheets[]>
> = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(RESPONSE);
    }, 1500);
  });
import styled from "styled-components";
import { getColor } from "../../_static/colors";

interface StyleProps {
  src: string;
  size: string;
  shadow: boolean;
}

const computeRules = ({ size, shadow, src }) => {
  let rules = `
    height: ${size};
    width: ${size};
    border-radius: ${size};
    background-image: url('${src}');
  `;

  if (shadow) {
    rules += `box-shadow: 0 0 24px 0px ${getColor("black")};`;
  }

  return rules;
};

export const Wrapper = styled.div<StyleProps>`
  ${(props) => computeRules(props)}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${getColor("white")};
`;

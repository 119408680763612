import styled from "styled-components";
import { Font, getColor } from "@mzt-monorepo/mzt-atomic";

const WHITE = getColor("white");
const ORANGE = getColor("orange");
const SEGEOUI = Font.SEGOEUI;
const GRAY = getColor("gray");

export const SuperContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow: auto;
  min-height: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
`;

export const SubmitContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-flow: column wrap;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-self: flex-end;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-basis: auto;
  margin: 0 0 12px 0;
`;

export const TopLabel = styled.div`
  display: flex;
  white-space: nowrap;
  font-family: ${SEGEOUI};
  font-size: 22px;
  color: ${WHITE};
  font-weight: bold;
  padding: 0px;
  letter-spacing: 0px;
  opacity: 1;
  flex-grow: 0;
  flex-basis: 10.5rem;
`;

export const Submit = styled.button`
  color: ${WHITE};
  font-family: ${SEGEOUI};
  align-self: flex-end;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  background-color: ${ORANGE};
  text-align: center;
  opacity: 1;
  width: 147px;
  height: 37px;
  cursor: pointer;
  outline: none;
  &:disabled {
    opacity: 0.65;
    background-color: ${GRAY};
  }
`;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Feedback = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25.673}
    height={34.833}
    viewBox="0 0 25.673 34.833"
    {...props}
  >
    <path
      d="M12.839 0a12.313 12.313 0 00-2.567.257A12.817 12.817 0 004.296 22.4a3.565 3.565 0 011.21 2.7v4.235A3.677 3.677 0 009.172 33h.513a3.629 3.629 0 006.307 0h.511a3.677 3.677 0 003.667-3.667V25.1a3.556 3.556 0 011.173-2.677A12.819 12.819 0 0012.839 0zm3.664 29.333H9.172V27.5h7.331zm0-3.667H9.172v-1.833h7.331zm-2.75-10.248v4.748h-1.831v-4.748l-3.355-3.337 1.3-1.3 2.97 2.97 2.97-2.97 1.3 1.3z"
      fill="#fa6f39"
    />
  </svg>
);

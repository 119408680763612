import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Timesheets = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={36.667}
    viewBox="0 0 33 36.667"
    {...props}
  >
    <path
      d="M11 16.5H7.333v3.667H11zm7.333 0h-3.666v3.667h3.667zm7.333 0H22v3.667h3.667zm3.667-12.833H27.5V0h-3.667v3.667H9.167V0H5.5v3.667H3.667A3.65 3.65 0 00.018 7.333L0 33a3.666 3.666 0 003.667 3.667h25.666A3.677 3.677 0 0033 33V7.333a3.677 3.677 0 00-3.667-3.666zm0 29.333H3.667V12.833h25.666z"
      fill="#fa6f39"
    />
  </svg>
);

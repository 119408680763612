import React, { useEffect } from "react";
import TimeSheetTemplate from "../../_templates/TimesheetTemplate";
import { ModalTimesheet } from "../ModalTimesheet";
import { getMonthMinMax } from "@mzt-monorepo/mzt-utils";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { fail, start, success } from "./../../../redux/actions/timesheetsPage";
import moment from "moment";
import { Container } from "./styled-components";
import { getTimesheets } from "../../Mocks/timesheet-mock";
import { getState } from "./../../../redux/selectors/timesheetsPage";

const fetchTimesheets = async (dispatch: Dispatch) => {
  dispatch(start());

  try {
    const res = await getTimesheets();
    dispatch(success(res.data));
  } catch (err) {
    dispatch(fail());
  }
};

const TimesheetHOC = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleShowModal = React.useCallback((open) => {
    setShowModal(open);
  }, []);

  const month = React.useMemo(() => {
    return getMonthMinMax(moment());
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchTimesheets(dispatch);
  }, [dispatch]);

  const state = useSelector(getState);

  return (
    <Container>
      <TimeSheetTemplate mock={state.list} handleShowModal={handleShowModal} />
      <ModalTimesheet
        isVisible={showModal}
        setVisible={setShowModal}
        month={month}
      />
    </Container>
  );
};

export default React.memo(TimesheetHOC);

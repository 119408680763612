import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Payments = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40.333}
    height={29.333}
    viewBox="0 0 40.333 29.333"
    {...props}
  >
    <path
      d="M33 18.333V3.667A3.677 3.677 0 0029.333 0H3.667A3.677 3.677 0 000 3.667v14.666A3.677 3.677 0 003.667 22h25.666A3.677 3.677 0 0033 18.333zM16.5 16.5A5.5 5.5 0 1122 11a5.493 5.493 0 01-5.5 5.5zm23.833-11v20.167a3.677 3.677 0 01-3.667 3.667H5.5v-3.667h31.167V5.5z"
      fill="#fa6f39"
    />
  </svg>
);

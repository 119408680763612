import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const File = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44.961}
    height={55.226}
    viewBox="0 0 44.961 55.226"
    {...props}
  >
    <path
      className="prefix__a"
      d="M11.136 35.531c0-.917-.636-1.464-1.759-1.464a3.894 3.894 0 00-.931.089v2.943a3.527 3.527 0 00.755.058c1.196-.001 1.935-.606 1.935-1.626zM18.087 34.098a4.642 4.642 0 00-1.02.089v6.511a4.074 4.074 0 00.784.045c2.04.014 3.37-1.108 3.37-3.488a2.858 2.858 0 00-3.134-3.157z"
    />
    <path
      className="prefix__a"
      d="M30.999 0H8.821a5.886 5.886 0 00-5.878 5.878v21.735h-.574a2.368 2.368 0 00-2.37 2.368v14.365a2.368 2.368 0 002.369 2.368h.574v2.634a5.884 5.884 0 005.878 5.878h30.265a5.884 5.884 0 005.876-5.878V13.911zM6.214 32.574a18.566 18.566 0 013.044-.206 4.776 4.776 0 013.045.8 2.912 2.912 0 011.064 2.305 3.184 3.184 0 01-.916 2.365 4.62 4.62 0 01-3.237 1.049 5.833 5.833 0 01-.769-.043V42.4H6.214zm32.872 19.052H8.821a2.28 2.28 0 01-2.276-2.278v-2.634h28.213a2.368 2.368 0 002.369-2.368V29.981a2.368 2.368 0 00-2.369-2.369H6.544V5.878A2.279 2.279 0 018.821 3.6l20.834-.022v7.7a4.078 4.078 0 004.076 4.076l7.544-.022.085 34.011a2.279 2.279 0 01-2.274 2.283zm-24.28-9.268v-9.784a20.287 20.287 0 013.045-.206 6.414 6.414 0 014.08 1.063 4.408 4.408 0 011.684 3.755 5.049 5.049 0 01-1.655 4.035 7.151 7.151 0 01-4.6 1.285 19.5 19.5 0 01-2.554-.148zm16.131-5.793V38.4H27.36v4h-2.261v-9.958h6.089v1.847H27.36v2.277z"
    />
  </svg>
);

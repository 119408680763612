import { ColorScheme, Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import {
  Item,
  Wrapper,
  ScrollableContainer,
  ContainerDeleteIcon,
  DeleteIcon,
} from "./styled-components";

interface SelectBodyProps {
  expanded: boolean;
  roundness: Roundness;
  colorScheme: ColorScheme;
  options: string[];
  hasReset: boolean;
  handleReset: () => void;
  handleSelect: (option: string) => void;
}

interface ItemProps {
  options: string[];
  clickCallback: (option: string) => void;
}

const Items = React.memo(({ options, clickCallback }: ItemProps) => (
  <>
    {options.map((option) => (
      <Item key={option} onClick={() => clickCallback(option)}>
        {option}
      </Item>
    ))}
  </>
));

export const SelectBody = ({
  expanded,
  roundness,
  colorScheme,
  options,
  hasReset,
  handleReset,
  handleSelect,
}: SelectBodyProps) => (
  <Wrapper $expanded={expanded} roundness={roundness} colorScheme={colorScheme}>
    <ScrollableContainer>
      <Items options={options} clickCallback={handleSelect} />
    </ScrollableContainer>
    {hasReset && (
      <ContainerDeleteIcon>
        <DeleteIcon onClick={handleReset} colorScheme={colorScheme} />
      </ContainerDeleteIcon>
    )}
  </Wrapper>
);

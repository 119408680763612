import React from "react";
import {
  HiddenFileInput,
  FileButton,
  Wrapper,
  ListWrapper,
  ListItem,
  ListDelete,
  ListIcon,
  ListText,
} from "./styled-components";
import { Label } from "../../label/";

//FIXES WARNING THAT INTERFACE DOES NOT EXIST IN FILE
import { InputFilesObject } from "./types";
import { Roundness } from "@mzt-monorepo/mzt-types";
export { InputFilesObject } from "./types";

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  button: string;
  roundness: Roundness;
  isHollow: boolean;
  files?: InputFilesObject;
}

const renderFilesList = (
  list: {
    [index: string]: File;
  },
  removeFile: (file: File) => void
) =>
  Object.keys(list).length === 0 ? null : (
    <ListWrapper>
      {Object.entries(list).map((file) => (
        <ListItem key={file[0]}>
          <ListIcon />
          <ListText
            onClick={() => window.open(URL.createObjectURL(file[1]), "_blank")}
          >
            {file[1].name}
          </ListText>
          <ListDelete onClick={() => removeFile(file[1])} />
        </ListItem>
      ))}
    </ListWrapper>
  );

export const FileInput = ({
  roundness = Roundness.FLAT,
  isHollow,
  label,
  button,
  files = null,
  ...props
}: FileInputProps) => (
  <Wrapper>
    <Label htmlFor={`FILE_INPUT_${props.id || ""}`}>{label}</Label>
    <HiddenFileInput
      {...props}
      type="file"
      id={`FILE_INPUT_${props.id || ""}`}
      onChange={files && files.add}
    />
    {files && renderFilesList(files.list, files.remove)}
    <FileButton
      roundness={roundness}
      isHollow={isHollow}
      onClick={() =>
        document.getElementById(`FILE_INPUT_${props.id || ""}`).click()
      }
      small={files && Object.keys(files.list).length !== 0 ? true : false}
    >
      {button}
    </FileButton>
  </Wrapper>
);

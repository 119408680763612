import { BaseAction } from "../../typescript/redux";
import TYPES from "../types/timesheets";

export interface TimesheetAction extends BaseAction {
  payload?: MockTimesheets[];
}

export const start = () => ({
  type: TYPES.START,
});

export const success: (payload: MockTimesheets[]) => TimesheetAction = (
  payload: MockTimesheets[]
) => ({
  type: TYPES.SUCCESS,
  payload,
});

export const fail = () => ({
  type: TYPES.FAIL,
});
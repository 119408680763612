import React, { useCallback, useEffect, useState } from "react";
import { Moment } from "moment";
import { Content, Wrapper, Month, Days, Day } from "./styled-components";
import { getMonthVacationDays } from "./../../../services/vacations";
import { Loading } from "@mzt-monorepo/mzt-atomic";

export interface MonthScrollerTabProps {
  isSelected?: boolean;
  date: Moment;
  onClick: (date: Moment) => void;
}

export const MonthScrollerTab = ({
  isSelected = false,
  date,
  onClick,
}: MonthScrollerTabProps) => {
  const [vacationDays, setVacationDays] = useState<MockVacationDays>({
    approved: 0,
    pending: 0,
    publicHolidays: 0,
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchVacationDays = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getMonthVacationDays(date);
      setVacationDays(response.data);
    } catch (err) {
      setVacationDays({
        approved: 0,
        pending: 0,
        publicHolidays: 0,
      });
    } finally {
      setLoading(false);
    }
  }, [date, setVacationDays]);

  useEffect(() => {
    fetchVacationDays();
  }, [fetchVacationDays]);

  return (
    <Wrapper $isSelected={isSelected} onClick={() => onClick(date)}>
      <Content>
        <Month>{date.format("MMM")}</Month>
        <Days>
          <Day $type="holiday">
            {isLoading ? <Loading /> : vacationDays.publicHolidays}
          </Day>
          <Day $type="approved">
            {isLoading ? <Loading /> : vacationDays.approved}
          </Day>
          <Day $type="pending">
            {isLoading ? <Loading /> : vacationDays.pending}
          </Day>
        </Days>
      </Content>
    </Wrapper>
  );
};

export default React.memo(MonthScrollerTab);

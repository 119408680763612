import styled, { css } from "styled-components";
import { getColor } from "../../_static/colors";
import { Arrow } from "../../_static/icons";

interface WrapperProps {
  $isPaginationOnTop: boolean;
  color: string;
}

interface ClickableProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  $isCurrent?: boolean;
}

export const Wrapper = styled.caption<WrapperProps>`
  display: ${({ $isPaginationOnTop }) =>
    $isPaginationOnTop ? "table-caption" : "flex"};
  color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
  background: none;
`;

export const Clickable = styled.button<ClickableProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: none;
  background: none;
  color: ${({ disabled }) => (disabled ? getColor("gray", 75) : "inherit")};
  padding: 8px 12px;
  transition: all 0.2s ease-in-out;
  font-weight: ${({ $isCurrent }) => $isCurrent && "bolder"};

  &:hover {
    text-decoration: ${({ disabled }) => !disabled && "underline"};
  }

  svg {
    fill: ${({ disabled }) =>
      disabled ? getColor("orange", 75) : getColor("orange")};
  }
`;

const svgStyle = css`
  height: 12px;
  width: 12px;
  padding: 0 8px;
`;

export const Left = styled(Arrow)`
  ${svgStyle}
  transform: rotate(90deg);
`;

export const Right = styled(Arrow)`
  ${svgStyle}
  transform: rotate(-90deg);
`;

import {
  TableColumn,
  TableData,
  UseTableSelectionReturn,
} from "@mzt-monorepo/mzt-types";
import React from "react";
import { Checkbox } from "../../../_atoms/checkbox";
import { Wrapper, Cell, SelectCell } from "./styled-components";

interface RowProps {
  data: TableData;
  index: number;
  selectable: UseTableSelectionReturn;
  columns: TableColumn[];
  handleSelectSingleRow: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: TableData
  ) => void;
}

const compareProps = (prev: RowProps, next: RowProps) => {
  //If key is not the same then it means the content of the row changed
  if (prev.data.key !== next.data.key) return false;
  //Needed to update the fill background upon re-ordering
  if (prev.index !== next.index) return false;
  //No need to re-render in case the isSelected has not changed
  if (prev.data.isSelected === next.data.isSelected) return true;

  //Default
  return false;
};

export const Row = ({
  data,
  index,
  selectable,
  columns,
  handleSelectSingleRow,
}: RowProps) => (
  <Wrapper key={data.key} $fillBackground={index % 2 === 0}>
    {selectable && !data.empty && (
      <SelectCell>
        <Checkbox
          checked={data.isSelected}
          onChange={(event) => handleSelectSingleRow(event, data)}
        />
      </SelectCell>
    )}
    {columns.map((col: TableColumn) => (
      <Cell key={col.key} size={col.size} alignment={col.alignment}>
        {React.isValidElement(data[col.dataIndex]) ? (
          data[col.dataIndex]
        ) : (
          <p>{data[col.dataIndex]}</p>
        )}
      </Cell>
    ))}
  </Wrapper>
);

export default React.memo(Row, compareProps);

import { AuthAction } from "../actions/auth";
import TYPES from "../types/auth";

interface State {
  isLoading: boolean;
  user: MockUser | null;
  success: boolean;
  isLoggedIn: boolean;
}

const initialState: State = {
  success: false,
  isLoggedIn: false,
  isLoading: false,
  user: null,
};

const reducer: (state: State, action: AuthAction) => State = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.SUCCESS:
      return {
        isLoading: false,
        isLoggedIn: true,
        success: true,
        user: action.payload,
      };
    case TYPES.FAIL:
      return {
        isLoading: false,
        isLoggedIn: false,
        success: false,
        user: null,
      };
    case TYPES.LOGOUT:
      return {
        isLoading: false,
        isLoggedIn: false,
        success: true,
        user: null,
      };
    default:
      return state;
  }
};

export default reducer;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const School = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40.333}
    height={33}
    viewBox="0 0 40.333 33"
    {...props}
  >
    <path
      d="M7.333 18.663V26l12.833 7L33 26v-7.337l-12.833 7zM20.167 0L0 11l20.167 11 16.5-9v12.667h3.667V11z"
      fill="#fa6f39"
    />
  </svg>
);

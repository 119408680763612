import { Moment } from "moment";
import React from "react";
import { Wrapper, Day, DayOfWeek } from "./styled-components";

interface VacationDaysSelectedDateProps {
  date: Moment | null;
}

export const VacationDaysSelectedDate = ({
  date,
}: VacationDaysSelectedDateProps) => (
  <Wrapper>
    <Day>{date ? date.format("DD") : ""}</Day>
    <div>
      <p>{date && date.format("MMMM YYYY")}</p>
      <DayOfWeek>{date && date.format("dddd")}</DayOfWeek>
    </div>
  </Wrapper>
);

export default VacationDaysSelectedDate;

import {
  Business,
  Feedback,
  FlightTakeoff,
  Groups,
  Help,
  Payments,
  School,
  Timesheets,
  Briefcase,
  Home as HomeIcon,
  Settings,
} from "@mzt-monorepo/mzt-atomic";
import Employees from "../app/_pages/Employees";
import Home from "../app/_pages/Home";
import Login from "../app/_pages/Login";
import TimeSheets from "../app/_pages/TimeSheets";
import Expenses from "../app/_pages/Expenses";
import Vacations from "../app/_pages/Vacations";
import FeedbackPage from "../app/_pages/Feedback";
import SettingsPage from "../app/_pages/Settings";
import Partners from "../app/_pages/Partners";
import Learning from "../app/_pages/Learning";
import Tickets from "../app/_pages/Tickets";
import TimesheetsAprovals from "../app/_pages/TimesheetApprovals";
import { RouteType, PATHS } from "./types";

export const ROUTES: RouteType[] = [
  {
    path: PATHS.HOME,
    component: Home,
    exact: true,
    isProtected: true,
    label: "Home",
    icon: HomeIcon,
  },
  {
    path: PATHS.LOGIN,
    component: Login,
    exact: true,
    isProtected: false,
    label: "Login",
  },
  {
    path: PATHS.TIMESHEETS,
    component: TimeSheets,
    exact: false,
    isProtected: true,
    label: "Timesheets",
    icon: Timesheets,
  },

  {
    path: PATHS.VACATIONS,
    component: Vacations,
    exact: false,
    isProtected: true,
    label: "Vacations",
    icon: FlightTakeoff,
  },
  {
    path: PATHS.EXPENSES,
    component: Expenses,
    exact: false,
    isProtected: true,
    label: "Expenses",
    icon: Payments,
  },
  {
    path: PATHS.LEARNING,
    component: Learning,
    exact: false,
    isProtected: true,
    label: "MZT Learning",
    icon: School,
  },
  {
    path: PATHS.PARTNERS,
    component: Partners,
    exact: false,
    isProtected: true,
    label: "Partners",
    icon: Business,
  },
  {
    path: PATHS.TICKETS,
    component: Tickets,
    exact: false,
    isProtected: true,
    label: "Tickets",
    icon: Help,
  },
  {
    path: PATHS.FEEDBACK,
    component: FeedbackPage,
    exact: false,
    isProtected: true,
    label: "Feedback",
    icon: Feedback,
  },
  {
    path: PATHS.EMPLOYEES,
    component: Employees,
    exact: false,
    isProtected: true,
    label: "Employees",
    icon: Groups,
  },
  {
    path: PATHS.SETTINGS,
    component: SettingsPage,
    exact: false,
    isProtected: true,
    label: "Settings",
    icon: Settings,
  },
  {
    path: PATHS.TIMESHEET_APPROVALS,
    component: TimesheetsAprovals,
    exact: false,
    isProtected: true,
    label: "Management",
    icon: Briefcase,
  },
];

import styled from "styled-components";
import { generateColorScheme, getColor } from "../../../_static/colors";
import { Expand } from "../../../_static/icons";
import { getRoundness } from "../../../_static/styles";
import { ExpandedProp, VisualStylingProps as StylingProps } from "../types";

const computeHeaderRules = ({ roundness, colorScheme }: StylingProps) => {
  let computed = "";

  computed += getRoundness(roundness);
  computed += generateColorScheme(colorScheme);

  return computed;
};

export const Wrapper = styled.div<StylingProps>`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  cursor: pointer;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;

  ${computeHeaderRules}
`;

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  * {
    max-width: 100%;
  }
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
`;

export const DropdownArrow = styled(Expand)<ExpandedProp>`
  fill: ${getColor("gray")};
  transition: transform 400ms ease-in-out;
  width: 24px;
  height: 24px;
  transform: rotate(${({ $expanded }) => ($expanded ? "-180deg" : "0deg")});
`;

import React from "react";
import VacationCalendar from "../../_atoms/VacationCalendar";
import VacationCalendarFooter from "../../_molecules/VacationCalendarFooter";
import { Block, Container } from "./styled-components";
import moment, { Moment } from "moment";
import VacationCalendarTimeoffGroup from "../../_molecules/VacationCalendarTimeoffGroup";

export interface VacationCalendarInteractionProps {
  handleEdit: (isEditing?: boolean) => void;
  fromDate: Moment;
  toDate: Moment;
  setDateInterval: (day: Moment) => void;
  isEditing: boolean;
  changeCurrentDate: (day: Moment) => void;
  currentDate: Moment;
}

export const VacationCalendarInteraction = ({
  handleEdit,
  fromDate,
  toDate,
  setDateInterval,
  isEditing,
  currentDate,
  changeCurrentDate,
}: VacationCalendarInteractionProps) => {
  const date = moment();

  return (
    <Container>
      <Block>
        <VacationCalendar
          setDateInterval={setDateInterval}
          fromDate={fromDate}
          toDate={toDate}
          isEditing={isEditing}
          changeCurrentDate={changeCurrentDate}
          currentDate={currentDate}
        />
        <VacationCalendarFooter handleClick={handleEdit} />
      </Block>
      <VacationCalendarTimeoffGroup date={date} />
    </Container>
  );
};

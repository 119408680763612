import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AccountBalance = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.667}
    height={39}
    viewBox="0 0 36.667 39"
    {...props}
  >
    <defs>
      <style>{".prefix__a{fill:#fa6f39}"}</style>
    </defs>
    <path
      className="prefix__a"
      d="M3.333 16h6v13h-6zM15.583 16.5h5.5v12.833h-5.5zM.333 33h36v6h-36zM27.333 16h6v13h-6zM18.333 0L0 9.167v3.667h36.667V9.167z"
    />
  </svg>
);

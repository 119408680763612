import { getRandomInt } from "@mzt-monorepo/mzt-utils";
import { Moment } from "moment";

const getResponse: (
  VACATION_DAYS: MockVacationDays
) => MockResponse<MockVacationDays> = (VACATION_DAYS: MockVacationDays) => ({
  success: true,
  data: VACATION_DAYS,
});

export const getMonthVacationDays: (
  date: Moment
) => Promise<MockResponse<MockVacationDays>> = (date: Moment) =>
  new Promise((resolve, reject) => {
    const VACATION_DAYS = {
      publicHolidays: getRandomInt(31),
      approved: getRandomInt(31),
      pending: getRandomInt(31),
    };
    setTimeout(() => {
      resolve(getResponse(VACATION_DAYS));
    }, 1500);
  });

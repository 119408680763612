import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FilePDF = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44.961"
    height="55.226"
    viewBox="0 0 44.961 55.226"
    {...props}
  >
    <g transform="translate(-90.701)">
      <path
        fill="#1f0065"
        className="a"
        d="M242.639,603.519c0-.917-.636-1.464-1.759-1.464a3.894,3.894,0,0,0-.931.089v2.943a3.527,3.527,0,0,0,.755.058C241.9,605.144,242.639,604.539,242.639,603.519Z"
        transform="translate(-140.802 -567.988)"
      />
      <path
        fill="#1f0065"
        className="a"
        d="M393.34,602.6a4.642,4.642,0,0,0-1.02.089V609.2a4.074,4.074,0,0,0,.784.045c2.04.014,3.37-1.108,3.37-3.488A2.858,2.858,0,0,0,393.34,602.6Z"
        transform="translate(-284.552 -568.502)"
      />
      <path
        fill="#1f0065"
        className="a"
        d="M121.7,0H99.522a5.886,5.886,0,0,0-5.878,5.878V27.613H93.07A2.368,2.368,0,0,0,90.7,29.981V44.346a2.368,2.368,0,0,0,2.369,2.368h.574v2.634a5.884,5.884,0,0,0,5.878,5.878h30.265a5.884,5.884,0,0,0,5.876-5.878V13.911ZM96.915,32.574a18.566,18.566,0,0,1,3.044-.206,4.776,4.776,0,0,1,3.045.8,2.912,2.912,0,0,1,1.064,2.305,3.184,3.184,0,0,1-.916,2.365,4.62,4.62,0,0,1-3.237,1.049,5.833,5.833,0,0,1-.769-.043V42.4H96.915Zm32.872,19.052H99.522a2.28,2.28,0,0,1-2.276-2.278V46.714h28.213a2.368,2.368,0,0,0,2.369-2.368V29.981a2.368,2.368,0,0,0-2.369-2.369H97.245V5.878A2.279,2.279,0,0,1,99.522,3.6l20.834-.022v7.7a4.078,4.078,0,0,0,4.076,4.076l7.544-.022.085,34.011A2.279,2.279,0,0,1,129.787,51.626Zm-24.28-9.268V32.574a20.287,20.287,0,0,1,3.045-.206,6.414,6.414,0,0,1,4.08,1.063,4.408,4.408,0,0,1,1.684,3.755,5.049,5.049,0,0,1-1.655,4.035,7.151,7.151,0,0,1-4.6,1.285A19.5,19.5,0,0,1,105.507,42.358Zm16.131-5.793V38.4h-3.577V42.4H115.8V32.442h6.089v1.847h-3.828v2.277Z"
      />
    </g>
  </svg>
);

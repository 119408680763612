import { FlightTakeoff } from "@mzt-monorepo/mzt-atomic";
import React, { useCallback, useState } from "react";
import VacationDaysBalance from "../../_atoms/VacationDaysBalance";
import MonthScroller from "../../_molecules/MonthScroller";
import PageTitle from "../../_molecules/PageTitle";
import { VacationCalendarInteraction } from "../../_organisms/VacationCalendarInteraction";
import { Block, CalendarView, Container } from "./styled-components";
import moment, { Moment } from "moment";
import VacationDaysSubmission from "../../_organisms/VacationDaysSubmission";
import VacationCalendarMultipleDisplay from "../../_molecules/VacationCalendarMultipleDisplay";

const Vacations = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [dateInterval, setDateInterval] = useState<[Moment, Moment]>([
    null,
    null,
  ]);
  const [currentDate, setCurrentDate] = useState<Moment>(moment());

  const changeCurrentDate = useCallback(
    (day: Moment) => {
      setCurrentDate(day);
    },
    [setCurrentDate]
  );

  const selectDate = useCallback(
    (day: Moment) => {
      if (editMode) {
        if (!day) {
          setDateInterval([null, null]);
        } else if (!dateInterval[0]) {
          setDateInterval([day, dateInterval[1]]);
        } else {
          if (day.isAfter(dateInterval[0])) {
            setDateInterval([dateInterval[0], day]);
          } else {
            if (!dateInterval[1]) {
              setDateInterval([day, dateInterval[0]]);
            } else {
              setDateInterval([day, dateInterval[1]]);
            }
          }
        }
      }
    },
    [setDateInterval, dateInterval, editMode]
  );

  const handleEdit = useCallback(
    (isEditing?: boolean) => {
      setEditMode(isEditing === undefined ? !editMode : isEditing);
    },
    [editMode]
  );

  const handleSubmit = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  return (
    <Container>
      <PageTitle icon={FlightTakeoff} title="VACATIONS" />
      <CalendarView>
        <MonthScroller
          changeCurrentDate={changeCurrentDate}
          currentDate={currentDate}
        />
        <Block>
          <VacationCalendarInteraction
            handleEdit={handleEdit}
            fromDate={dateInterval[0]}
            toDate={dateInterval[1]}
            setDateInterval={selectDate}
            isEditing={editMode}
            changeCurrentDate={changeCurrentDate}
            currentDate={currentDate}
          />
          <VacationDaysBalance
            currYearBalance={0}
            lastYearBalance={0}
            annualAllowance={0}
          />
        </Block>
        {editMode ? (
          <VacationDaysSubmission
            isEditing={editMode}
            handleSubmit={handleSubmit}
            fromDate={dateInterval[0]}
            toDate={dateInterval[1]}
          />
        ) : (
          <VacationCalendarMultipleDisplay currentDate={currentDate} />
        )}
      </CalendarView>
    </Container>
  );
};

export default Vacations;

import axios from "axios";
import { Credentials } from "@mzt-monorepo/mzt-intranet-types";
import { mztConf } from "@mzt-monorepo/mzt-intranet-conf";

export const baseServiceURL = "uas/account/";

export const createUser = () =>
  axios.post(`${mztConf().service}${baseServiceURL}register`);

export const login = (credentials: Credentials) =>
  axios.post(`${mztConf().service}${baseServiceURL}login`, credentials);

export const auth = () =>
  axios.get(`${mztConf().service}${baseServiceURL}auth`);

export const accountService = {
  createUser,
  login,
  auth,
};

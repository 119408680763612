import React from "react";
import { Container } from "./styled-components";
import NavbarTab from "../../_molecules/NavbarTab";
import { useLocation } from "react-router-dom";
import { PATHS, RouteType } from "./../../../routes/types";
import { ROUTES } from "./../../../routes/routes";

const printLinks = (pathname: string) =>
  [
    PATHS.HOME,
    PATHS.TIMESHEETS,
    PATHS.VACATIONS,
    PATHS.EXPENSES,
    PATHS.LEARNING,
    PATHS.PARTNERS,
    PATHS.FEEDBACK,
    PATHS.TICKETS,
    PATHS.EMPLOYEES,
    PATHS.TIMESHEET_APPROVALS,
  ].map((path: string, index: number) => {
    const route: RouteType = ROUTES.find(
      (route: RouteType) => route.path === path
    );

    if (!route) return null;

    return (
      <NavbarTab
        key={index}
        aria-label={`Go to ${route.label}`}
        to={route.path}
        isActive={pathname === route.path}
        icon={route.icon}
      >
        {route.label}
      </NavbarTab>
    );
  });

const Navbar = () => {
  const location = useLocation();

  return <Container>{printLinks(location.pathname || "/")}</Container>;
};

export default Navbar;

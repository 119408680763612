import { Tag } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  margin: 0 1rem;
`;

export const VacationTag = styled(Tag)`
  margin: 0;
  box-sizing: border-box;
  min-width: 100%;
`;

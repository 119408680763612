import { getMediaQueryBreakpoint } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 32px;
  width: 272px;
  padding: 72px 72px 72px 0;

  @media only screen and (${getMediaQueryBreakpoint("max-width", "laptop")}) {
    display: none;
  }
`;

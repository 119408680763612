import React, { useCallback, useState } from "react";
import { Wrapper, SelectLabel, Container } from "./styled-components";
import { Roundness } from "@mzt-monorepo/mzt-types";
import { SelectProps } from "./types";
import { DEFAULT_COLOR_SCHEME } from "../../_static/colors";
import { SelectHeader } from "./header";
import { SelectBody } from "./body";

export const Select = ({
  roundness = Roundness.FLAT,
  hasSearch = false,
  hasReset = false,
  options = [],
  defaultText = "",
  selection = { value: null, handler: () => null, reset: () => null },
  search = { handler: () => null, reset: () => null, value: "" },
  colorScheme = DEFAULT_COLOR_SCHEME,
  label = null,
}: SelectProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const handleSelect = useCallback(
    (option: string) => {
      selection.handler(option);
      setExpanded(false);
    },
    [selection]
  );

  const handleReset = useCallback(() => {
    setExpanded(false);
    selection.reset();
  }, [selection]);

  return (
    <Container>
      {label && <SelectLabel onClick={toggleExpand}>{label}</SelectLabel>}
      <Wrapper>
        <SelectHeader
          colorScheme={colorScheme}
          currentValue={selection.value}
          defaultValue={defaultText}
          expanded={expanded}
          hasSearch={hasSearch}
          roundness={roundness}
          search={search}
          toggleExpand={toggleExpand}
        />
        <SelectBody
          colorScheme={colorScheme}
          expanded={expanded}
          handleReset={handleReset}
          handleSelect={handleSelect}
          hasReset={hasReset}
          options={options}
          roundness={roundness}
        />
      </Wrapper>
    </Container>
  );
};

import { Email, Name, Wrapper } from "./styled-components";
import React from "react";

interface TableNameProps {
  name: string;
  email: string;
}

export const TableName: React.FC<TableNameProps> = ({ name, email }) => (
  <Wrapper>
    <Name>{name}</Name>
    <Email>{email}</Email>
  </Wrapper>
);

export default TableName;

import React from "react";
import { Container } from "./styled-components";
import { Moment } from "moment";

import TimesheetItem from "../TimesheetItem";

interface Props {
  mock: {
    month: Moment;
    project: string;
    status: string;
    limitDate: string;
    manager: string;
    cardDays: number[];
  }[];
  handleItemClick: (state: string) => void;
  handleShowModal: (state: boolean) => void;
}

const TimeSheetList = (props: Props) => {
  const RenderedTimesheets = React.useMemo(
    () =>
      props.mock.map((data) => (
        <TimesheetItem
          {...data}
          key={data.month.toString()}
          handleItemClick={props.handleItemClick}
          handleShowModal={props.handleShowModal}
        />
      )),
    [props.handleItemClick, props.handleShowModal, props.mock]
  );

  return <Container>{RenderedTimesheets}</Container>;
};

export default React.memo(TimeSheetList);

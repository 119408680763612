import React from "react";
import TimesheetHOC from "../../_components/TimesheetHOC";
import { Container } from "./styled-components";

const Timesheet = () => (
  <Container>
    <TimesheetHOC />
  </Container>
);

export default Timesheet;

import React from "react";
import { ColorTypes, getColor } from "../../_static/colors";
import { iconsPath, SvgType } from "../../_static/iconography";

export interface IconProps {
  type: SvgType;
  size?: number;
  color?: ColorTypes;
}

export const Icon = ({ type, size = 24, color = "black" }: IconProps) => (
  <svg
    viewBox="0 0 50 50"
    width={size}
    height={size}
    fill={getColor(color)}
    preserveAspectRatio="xMidYMid meet"
  >
    <g>{iconsPath[type].map((element) => element)}</g>
  </svg>
);

export default Icon;

import { Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import {
  Wrapper,
  ModalDatesLabel,
  InputWrapper,
  ModalInput,
  Icon,
} from "./styled-components";

interface ModalDateInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  roundness: Roundness;
}

export const ModalDateInput = React.memo(
  ({ ...props }: ModalDateInputProps) => (
    <Wrapper>
      <ModalDatesLabel htmlFor={`DATE_INPUT_${props.id}`}>
        Start date
      </ModalDatesLabel>
      <InputWrapper>
        <ModalInput {...props} type="date" id={`DATE_INPUT_${props.id}`} />
        <Icon
          onClick={() => {
            document.getElementById(`DATE_INPUT_${props.id}`).focus();
          }}
        />
      </InputWrapper>
    </Wrapper>
  )
);

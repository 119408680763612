import styled, { css } from "styled-components";
import { getColor } from "@mzt-monorepo/mzt-atomic";

interface StyleProps {
  lineColor: string;
}

const computeRules = ({ lineColor }: StyleProps) => css`
  border-bottom: 4px solid ${lineColor};
`;

export const Wrapper = styled.h1<StyleProps>`
  box-sizing: border-box;
  color: ${getColor("darkBlue")};
  display: inline;
  font-weight: 500;
  font-size: 24px;
  ${computeRules}
`;

import moment, { Moment } from "moment";
import React from "react";
import VacationCalendarTimeoff from "../../_atoms/VacationCalendarTimeoff";
import { Container } from "./styled-components";

interface Props {
  date: Moment;
}

export const VacationCalendarTimeoffGroup = ({ date }: Props) => {
  return (
    <Container>
      <VacationCalendarTimeoff date={date}>
        Previous vacation timeoff
      </VacationCalendarTimeoff>
      <VacationCalendarTimeoff date={date}>
        Next vacation timeoff
      </VacationCalendarTimeoff>
      <VacationCalendarTimeoff date={date}>
        Next public holiday
      </VacationCalendarTimeoff>
    </Container>
  );
};

export default VacationCalendarTimeoffGroup;

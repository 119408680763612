import styled from "styled-components";
import { getColor, Timesheets as T } from "@mzt-monorepo/mzt-atomic";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  * {
    cursor: pointer;
    width: 18px;
    margin: 0 4px;
  }
`;

export const Timesheets = styled(T)`
  path {
    fill: ${getColor("darkBlue")};
  }
`;

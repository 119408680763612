import React, { useState, useCallback, useMemo } from "react";
import { InputFilesObject } from "../_atoms/input/file";

export const useFileInput = (): InputFilesObject => {
  const [state, setState] = useState<{ [index: string]: File }>({});

  const add = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files.length) return;
    setState((prevState) => {
      const nextState = { ...prevState };
      nextState[e.target.files[0].name] = e.target.files[0];
      return nextState;
    });
  }, []);

  const remove = useCallback((file: File) => {
    setState((prevState) => {
      const nextState = { ...prevState };
      delete nextState[file.name];
      return nextState;
    });
  }, []);

  return useMemo(
    () => ({
      list: state,
      add,
      remove,
    }),
    [state, add, remove]
  );
};

import styled from "styled-components";
import { Card, getColor, Font } from "@mzt-monorepo/mzt-atomic";

export const Container = styled(Card)`
  box-shadow: 0px 0px 5px -2px ${getColor("black")};
  min-width: 300px;
  max-width: 500px;
  min-height: 552px;
  width: 35%;
  height: 75vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: ${Font.MONUMENT};
  font-weight: normal;
  font-size: 12px;

  h1 {
    font-weight: inherit;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 64px;
  box-sizing: border-box;
  width: 100%;
`;

export const InputGroup = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  padding: 16px 0;
  color: ${getColor("gray")};
  font-size: 8px;
`;

import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.section`
  background-color: ${getColor("gray", 15)};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  margin: 40px 0;
  padding: 16px;
  gap: 24px;
  border-radius: 16px;
  color: ${getColor("darkBlue")};
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: center;
  font-size: 16px;
`;

export const Divider = styled.span`
  color: ${getColor("orange")};
  margin: 0 1.5rem;
`;

import {
  Select,
  TextArea,
  Label,
  SelectObject,
  Input,
  InputFilesObject,
  Modal,
  getColor,
} from "@mzt-monorepo/mzt-atomic";
import { Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import {
  Wrapper,
  RowContainer,
  ItemContainer,
  ModalTimesheetSubmit,
} from "./styled-components";
import { ModalTimesheetDate } from "../../_atoms/ModalTimesheetDates";
import { Moment } from "moment";
import { ModalTitle } from "../../_atoms/ModalTitle";

const MemoizedSelect = React.memo(Select);
const MemoizedTextarea = React.memo(TextArea);
const MemoizedFileInput = React.memo(Input.File);

interface DateFormat {
  value: Moment;
  handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ModalTimesheetBody {
  select: SelectObject;
  dates: {
    from: DateFormat;
    to: DateFormat;
    min: string;
    max: string;
  };
  notes: {
    value: string;
    handler: React.ChangeEventHandler<HTMLTextAreaElement>;
  };
  documents: InputFilesObject;
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
  onSubmit: () => void;
}

const HEADER_COLOR = getColor("orange");
const header = <ModalTitle lineColor={HEADER_COLOR}>Add Time Off</ModalTitle>;

export const ModalTimesheetTemplate = ({
  select,
  dates,
  notes,
  documents,
  isVisible,
  setVisible,
  onSubmit,
}: ModalTimesheetBody) => (
  <Modal isVisible={isVisible} setVisible={setVisible} header={header}>
    <Wrapper>
      <RowContainer>
        <ItemContainer>
          <MemoizedSelect
            label="Reason"
            {...select}
            hasSearch
            roundness={Roundness.SEMI_ROUND}
          />
        </ItemContainer>
        <ItemContainer>
          <ModalTimesheetDate
            fromDate={dates.from.value}
            toDate={dates.to.value}
            fromDateChange={dates.from.handler}
            toDateChange={dates.to.handler}
            min={dates.min}
            max={dates.max}
          />
        </ItemContainer>
      </RowContainer>
      <RowContainer>
        <ItemContainer>
          <Label htmlFor="Notes">Notes</Label>
          <MemoizedTextarea
            id="Notes"
            resize="vertical"
            roundness={Roundness.SEMI_ROUND}
            value={notes.value}
            onChange={notes.handler}
          />
        </ItemContainer>
        <ItemContainer>
          <MemoizedFileInput
            id="Timesheets"
            isHollow
            roundness={Roundness.ROUND}
            label="Documents"
            button="+ Add new Documents"
            files={documents}
          />
        </ItemContainer>
      </RowContainer>
      <ModalTimesheetSubmit roundness={Roundness.ROUND} onClick={onSubmit}>
        SUBMIT
      </ModalTimesheetSubmit>
    </Wrapper>
  </Modal>
);

import styled from "styled-components";
import { Font, getColor } from "@mzt-monorepo/mzt-atomic";

const WHITE = getColor("white");
const SEGEOUI = Font.SEGOEUI;
const MDBLUE = getColor("middleDarkBlue");
const ORANGE = getColor("orange");

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TimeSheetContainer = styled.div`
  display: flex;
  flex-grow: 0;
  border-radius: 20px;
  background: ${MDBLUE};
  opacity: 1;
  flex-basis: auto;
  height: 60px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 0 0 24px 0;
`;

export const TimeSheetLabel = styled.div`
  display: flex;
  white-space: nowrap;
  align-self: center;
  text-align: center;
  font-family: ${SEGEOUI};
  font-size: 18px;
  color: ${WHITE};
  font-weight: 550;
  padding: 24px;
  flex-grow: 0;
  width: 40%;
  letter-spacing: 0px;
  opacity: 1;
  flex-basis: 8rem;
`;

export const ArrowButton = styled.button`
  fill: ${WHITE};
  background-color: Transparent;
  background-repeat: no-repeat;
  display: flex;
  flex-grow: 0;
  white-space: nowrap;
  justify-content: center;
  align-self: center;
  border: none;
  font-size: 30px;
  opacity: 1;
  cursor: pointer;
  outline: none;
`;

export const AddTimeOff = styled.button`
  color: ${WHITE};
  font-family: ${SEGEOUI};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  background-color: ${ORANGE};
  text-align: center;
  margin: 17px 0 24px 0;
  opacity: 1;
  width: 147px;
  height: 37px;
  cursor: pointer;
  &:disabled {
    opacity: 0.65;
  }
`;

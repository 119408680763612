import { BaseAction } from "../../typescript/redux";
import TYPES from "../types/users";

export interface AuthAction extends BaseAction {
  payload?: MockUser[];
}

export const start = () => ({
  type: TYPES.START,
});

export const success: (payload: MockUser[]) => AuthAction = (
  payload: MockUser[]
) => ({
  type: TYPES.SUCCESS,
  payload,
});

export const fail = () => ({
  type: TYPES.FAIL,
});

export enum PaginationConfigPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

export interface PaginationConfig {
  position: false | PaginationConfigPosition;
  numberRows: number;
  color?: string;
}

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FlightTakeoff = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37.18}
    height={31.955}
    viewBox="0 0 37.18 31.955"
    {...props}
  >
    <path
      d="M1.21 28.288h34.833v3.667H1.21zM37.088 11.13a2.744 2.744 0 00-3.373-1.943l-9.735 2.6L11.33 0 7.792.935l7.59 13.145-9.112 2.438-3.61-2.823L0 14.41l4.748 8.232s13.053-3.483 30.378-8.122a2.791 2.791 0 001.962-3.39z"
      fill="#fa6f39"
    />
  </svg>
);

import React, { useCallback, useEffect, useMemo, useState } from "react";

const filterOptions = (options: string[], value: string) =>
  options.filter((option) =>
    option.toLowerCase().includes(value.toLowerCase())
  );

export interface SelectObject {
  options: string[];
  selection: {
    value: string;
    handler: (option: string) => void;
    reset: () => void;
  };
  search: {
    value: string;
    handler: (e: React.ChangeEvent<HTMLInputElement>) => void;
    reset: () => void;
  };
}

export const useSelect = (options: string[] = []) => {
  const [selection, setSelected] = useState<string | null>(null);
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
  const [currentSearch, setCurrentSearch] = useState<string>("");

  //SEARCH METHODS
  const searchHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentSearch(e.target.value);
    },
    []
  );

  const searchReset = useCallback(() => {
    setCurrentSearch("");
  }, []);

  //SELECTION METHODS
  const selectionHandler = useCallback((option: string) => {
    setSelected(option);
    setCurrentSearch("");
  }, []);

  const selectionReset = useCallback(() => {
    setSelected(null);
    searchReset();
  }, [searchReset]);

  //Resets the whole hook when options array changes
  useEffect(() => {
    setSelected(null);
    setFilteredOptions(options);
    setCurrentSearch("");
  }, [options]);

  //Updates the currentOptions based on what the search is
  useEffect(() => {
    setFilteredOptions(filterOptions(options, currentSearch));
  }, [currentSearch, options]);

  return useMemo(
    () => ({
      options: filteredOptions,
      selection: {
        value: selection,
        handler: selectionHandler,
        reset: selectionReset,
      },
      search: {
        value: currentSearch,
        handler: searchHandler,
        reset: searchReset,
      },
    }),
    [
      currentSearch,
      filteredOptions,
      searchHandler,
      searchReset,
      selection,
      selectionHandler,
      selectionReset,
    ]
  );
};

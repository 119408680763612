import { Moment } from "moment";
import React from "react";
import VacationDaysSelection from "../../_molecules/VacationDaysSelection";
import { Container, Row, Submit } from "./styled-components";

export interface VacationDaysSubmissionProps {
  fromDate: Moment | null;
  toDate: Moment | null;
  handleSubmit: () => void;
  isEditing: boolean;
}

export const VacationDaysSubmission = ({
  fromDate,
  toDate,
  handleSubmit,
  isEditing,
}: VacationDaysSubmissionProps) => (
  <Container>
    {isEditing && (
      <>
        <VacationDaysSelection fromDate={fromDate} toDate={toDate} />
        <Row>
          TOTAL:{" "}
          <b>
            {fromDate && toDate ? toDate.diff(fromDate, "days") : "0"} day(s)
          </b>
        </Row>
        <Row>
          REMAINING: <b>10 day(s)</b>
        </Row>
        <Submit onClick={handleSubmit}>Submit</Submit>
      </>
    )}
  </Container>
);

export default VacationDaysSubmission;

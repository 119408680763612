import React, { useCallback, useState, useMemo, useEffect } from "react";
import {
  SortConfig,
  TableData,
  TableProps,
  SortDirection,
} from "@mzt-monorepo/mzt-types";
import { Wrapper } from "./styled-components";
import { orderBy } from "lodash";
import Pagination from "../../_atoms/pagination";
import TableHeader from "./header";
import { TableBody } from "./body";
import { paginateData, mapSelect, dataInit } from "./utils";

export const Table = ({
  columns,
  data,
  showHeader = true,
  sortBy,
  selectable,
  pagination = undefined,
}: TableProps) => {
  const [headerSelect, setHeaderSelect] = useState<boolean>(false);
  const [sort, setSort] = useState<string | SortConfig>(sortBy);
  const [tableData, setTableData] = useState(dataInit(data, sortBy));
  const [currPage, setCurrPage] = useState<number>(0);
  const numberOfPages =
    pagination && Math.ceil(tableData.length / pagination.numberRows);

  /**
   * Orders the data received by the sort parameter. If it's a string, it means no argument was passed and it shall sort the data
   * by the default sort arg, the "key" column which is handled by the user. If not, it will sort the data by the options passed to the
   * component.
   */
  const sortData = useCallback(
    (dataArg: TableData[], sort: SortConfig | string) =>
      setTableData(
        orderBy(
          dataArg || data,
          typeof sort === "string" ? sort : sort.cols,
          typeof sort === "string" ? "asc" : sort.directions
        )
      ),
    [data]
  );

  /**
   * Function that will switch the column's sorting direction
   *
   * @param index Position of the column's dataIndex
   * @param direction New direction to use in the sorting array
   */
  const handleSortDirection = useCallback(
    (index: number, direction: SortDirection | undefined) => {
      if (typeof sort !== "string") {
        // Copy of the state
        const newSort: SortConfig = {
          cols: [...sort.cols],
          directions: [...sort.directions],
        };

        // If it exists, it switches it, if not, it adds a direction to that index
        if (direction) {
          newSort.directions[index] = direction;
        } else {
          newSort.directions.push("asc");
        }
        setSort(newSort);
        sortData(tableData, newSort);
      }
    },
    [setSort, sortData, sort, tableData]
  );

  const handleSelectAllRows = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTableData((previousData) =>
        mapSelect(previousData, event.target.checked, selectable, () => true)
      );
      setHeaderSelect(event.target.checked);
    },
    [selectable]
  );

  const handleSelectSingleRow = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, rowArg: TableData) => {
      const comparator = (row: TableData) => row.key === rowArg.key;
      setTableData((previousData) =>
        mapSelect(previousData, e.target.checked, selectable, comparator)
      );
    },
    [selectable]
  );

  /**
   * Changes the current page state
   * @param pagesToAdd Number of pages to add to our state
   */
  const handlePage = useCallback(
    (goToPage: number) => {
      // In order to complete this operation, the current page should never be lower than 0 or higher
      // than the number of pages we're dealing with
      if (goToPage >= 0 && goToPage < numberOfPages) setCurrPage(goToPage);
    },
    [setCurrPage, numberOfPages]
  );

  /**
   * Returns a non-paginated table or a paginated table, depending if a config was passed or not
   */
  const paginatedData = useMemo(() => {
    return paginateData(pagination, tableData, currPage);
  }, [tableData, currPage, pagination]);

  //Checks the header checkbox if all rows are selected
  useEffect(() => {
    if (tableData.find((row) => !row.isSelected)) {
      setHeaderSelect(false);
    } else {
      setHeaderSelect(true);
    }
  }, [tableData]);

  return (
    <>
      <Wrapper>
        {showHeader && (
          <TableHeader
            columns={columns}
            handleSelectAllRows={handleSelectAllRows}
            handleSortDirection={handleSortDirection}
            selectable={selectable}
            sort={sort}
            isSelected={headerSelect}
          />
        )}
        <TableBody
          columns={columns}
          data={paginatedData}
          handleSelectSingleRow={handleSelectSingleRow}
          selectable={selectable}
        />
      </Wrapper>
      {pagination && (
        <Pagination
          pagination={pagination}
          currPage={currPage}
          numberOfPages={numberOfPages}
          handlePage={handlePage}
        />
      )}
    </>
  );
};

export default Table;

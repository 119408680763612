import { combineReducers } from "redux";
import auth from "./auth";
import users from "./users";
import timesheets from "./timesheets";
import timesheetsPage from "./timesheetsPage";

const reducers = combineReducers({
  auth,
  users,
  timesheets,
  timesheetsPage,
});

export default reducers;

import styled from "styled-components";
import { Font, getColor } from "@mzt-monorepo/mzt-atomic";

const WHITE = getColor("white");
const SEGEOUI = Font.SEGOEUI;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-basis: auto;
  margin: 0 0 12px 0;
`;

export const TopLabel = styled.div`
  display: flex;
  white-space: nowrap;
  font-family: ${SEGEOUI};
  font-size: 22px;
  color: ${WHITE};
  font-weight: bold;
  padding: 0px;
  letter-spacing: 0px;
  opacity: 1;
  flex-grow: 0;
  flex-basis: 10.5rem;
`;

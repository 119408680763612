import styled from "styled-components";
import { getColor } from "../../../_static/colors";
import { RowProps } from "../styled-components";

export const Wrapper = styled.tbody``;

export const Row = styled.tr<RowProps>`
  display: flex;
  flex-grow: 1;
  background-color: ${({ $fillBackground }) =>
    $fillBackground && getColor("darkBlue", 10)};
`;

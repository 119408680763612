import styled, { css } from "styled-components";
import { ColumnSizes, Alignment } from "@mzt-monorepo/mzt-types";
import { getColor } from "../../_static/colors";

export const Wrapper = styled.table`
  color: ${getColor("darkBlue")};
  width: 100%;
`;

//CHILDREN COMMON STYLES
export interface RowProps {
  $fillBackground?: boolean;
}

export interface CellProps {
  alignment?: Alignment;
  size?: ColumnSizes;
}

export interface HeaderCellProps extends CellProps {
  $hasSortingFeature: boolean;
}

export const ComputeSizeAndAlignment = ({
  size = 1,
  alignment = Alignment.CENTER,
}: CellProps) => css`
  flex-grow: ${size};
  text-align: ${alignment};
`;

export const cellStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  width: 60px;
  overflow: hidden;
  flex-grow: 1;

  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: inherit;
  }
`;

export const selectCellStyle = css`
  ${cellStyle};
  width: 60px;
  flex-grow: 0;
`;

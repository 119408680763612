import { Roundness } from "@mzt-monorepo/mzt-types";
import styled from "styled-components";
import { InputVariant } from ".";
import { getColor } from "../../_static/colors";
import { getRoundness } from "../../_static/styles";

interface StyleProps {
  isMinimal: boolean;
  roundness: Roundness;
  variant: InputVariant;
  isHollow: boolean;
}

const getVariantColor = (variant: InputVariant) => {
  switch (variant) {
    case "primary":
      return getColor("gray");
    case "secondary":
      return getColor("white");
    default:
      return getColor("gray");
  }
};

export const Wrapper = styled.input<StyleProps>`
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: 32px;
  min-width: 64px;
  padding: 0 16px;
  border-color: ${({ variant }) => getVariantColor(variant)};
  border-width: ${({ isMinimal }) => (isMinimal ? "0 0 1px 0" : "1px")};
  border-style: solid;
  background-color: ${({ isHollow }) =>
    isHollow ? "transparent" : getColor("white")};
  color: ${({ variant }) => getVariantColor(variant)};
  ${({ roundness }) => getRoundness(roundness)}
`;

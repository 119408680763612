import { getColor } from "@mzt-monorepo/mzt-atomic";
import { injectContentStyle } from "../../../styles/generators";
import styled from "styled-components";
import Main from "../../../wrappers/Main";

export const Container = styled(Main)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const CalendarView = styled.div`
  display: flex;
  background-color: ${getColor("white")};
  ${injectContentStyle}
  flex-grow: 1;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow: auto;
  background: green;
  min-height: 0;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 54px;
  flex: 1;
`;

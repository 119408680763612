import styled from "styled-components";
import { Button as btn } from "@mzt-monorepo/mzt-atomic";

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled(btn)`
  text-transform: initial;
  text-overflow: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 5px 24px;
  height: auto;
  width: auto;
`;

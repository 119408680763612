import { PaginationConfig } from "./pagination";
import { UseTableSelectionReturn } from "../hooks/useTableSelection";

export type SortDirection = "asc" | "desc";

export type ResizerPosition = "left" | "right" | "bottom" | "top";

export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum ColumnSizes {
  FIT = 0.1,
  NO_GROW = 0.1,
  SMALLER = 2,
  SMALL = 3,
  MEDIUM = 4,
  LARGE = 5,
}

export interface SortConfig {
  cols: string[];
  directions: SortDirection[];
}

export interface TableData {
  key: string | number;
  isSelected?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [index: string]: any;
  empty?: boolean;
}

export interface TableColumn {
  key: string | number;
  title: string;
  dataIndex: string;
  alignment?: Alignment;
  size?: ColumnSizes;
}

export interface TableProps {
  columns: TableColumn[];
  data: TableData[];
  showHeader?: boolean;
  sortBy?: string | SortConfig;
  selectable?: UseTableSelectionReturn;
  pagination?: PaginationConfig | null;
}

import { useSelect, useFileInput } from "@mzt-monorepo/mzt-atomic";
import { useDateInterval } from "../../../hooks/dateInterval";
import React, { useCallback, useMemo, useState } from "react";
import { ModalTimesheetTemplate } from "../../_templates/ModalTimesheetTemplate";

const OPTIONS = [
  "Sickness",
  "Justified Absence",
  "Unjustified Absence",
  "Birth Permit",
  "Final Exams",
  "Holiday",
  "Mariage Permit",
  "Pre-Birth",
];

interface ModalTimesheetProps {
  isVisible: boolean;
  setVisible: (state: boolean) => void;
  month: {
    min: string;
    max: string;
  };
}

export const ModalTimesheet = ({
  isVisible = true,
  setVisible,
  month,
}: ModalTimesheetProps) => {
  const select = useSelect(OPTIONS);
  const fileInput = useFileInput();
  const [notes, setNotes] = useState("");
  const dateInterval = useDateInterval([null, null], [month.min, month.max]);

  const handleNotesChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNotes(e.target.value);
    },
    []
  );

  const dates = useMemo(
    () => ({
      from: {
        value: dateInterval.state[0],
        handler: dateInterval.handlers.from,
      },
      to: {
        value: dateInterval.state[1],
        handler: dateInterval.handlers.to,
      },
      min: dateInterval.min,
      max: dateInterval.max,
    }),
    [dateInterval]
  );

  const notesProps = useMemo(
    () => ({ value: notes, handler: handleNotesChange }),
    [notes, handleNotesChange]
  );

  return (
    <ModalTimesheetTemplate
      select={select}
      dates={dates}
      notes={notesProps}
      documents={fileInput}
      isVisible={isVisible}
      setVisible={setVisible}
      onSubmit={() => null}
    />
  );
};

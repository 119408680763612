import React from "react";
import { Wrapper, Timesheets } from "./styled-components";
import { CrossFlat, CheckFlat, TrashBin } from "@mzt-monorepo/mzt-atomic";

interface ActionProps {
  expandCallback?: () => void;
  acceptCallback?: () => void;
  rejectCallback?: () => void;
  deleteCallback?: () => void;
}

export const TableActions = ({
  expandCallback,
  acceptCallback,
  rejectCallback,
  deleteCallback,
}: ActionProps) => (
  <Wrapper>
    {expandCallback && <Timesheets onClick={expandCallback} />}
    {acceptCallback && <CheckFlat onClick={acceptCallback} />}
    {rejectCallback && <CrossFlat onClick={rejectCallback} />}
    {deleteCallback && <TrashBin onClick={deleteCallback} />}
  </Wrapper>
);

export default TableActions;

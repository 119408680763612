import {
  SortConfig,
  TableData,
  UseTableSelectionReturn,
  PaginationConfig,
} from "@mzt-monorepo/mzt-types";
import { orderBy } from "lodash";

export const dataInit = (
  data: TableData[],
  sortBy: SortConfig | string
): TableData[] => {
  if (sortBy) {
    return orderBy(
      data,
      typeof sortBy === "string" ? sortBy : sortBy.cols,
      typeof sortBy === "string" ? "asc" : sortBy.directions
    );
  }
  return data;
};

export const paginateData = (
  pagination: PaginationConfig,
  tableData: TableData[],
  currentPage: number
) => {
  // If there is no pagination, it returns the full data in one page
  if (!pagination) return tableData;

  // Creates a copy of our tableData
  const copy = [...tableData];
  // Slices that copy, depending on the page we are at. For example, if we are at page 2,
  // and we want 10 rows for page, we will have an interval from index 20 (page 2 * 10 rows) to
  // index 30 (page 2 + 1 which is 3, times 10 number of rows which is 30).
  const page = copy.slice(
    currentPage * pagination.numberRows,
    (currentPage + 1) * pagination.numberRows
  );

  // We now fill up the remaining empty spaces so we won't have to deal with a disformatted
  // table with css
  let key = "";
  while (page.length > 0 && page.length < pagination.numberRows) {
    key += "_";
    const EMPTY_ROW: TableData = {
      key: key,
      isSelected: false,
      empty: true,
    };
    page.push(EMPTY_ROW);
  }

  // And we return our page
  return page;
};

/**
 * Function that maps the tableData to handle selections
 *
 * @param tableData Array containing all rows
 * @param nextState Next value
 * @param selectable
 * @param comparator function that is called in each iteration with a row, the function shall provide true if this specific row should change, provide false if not
 */
export const mapSelect = (
  tableData: TableData[],
  nextState: boolean,
  selectable: UseTableSelectionReturn,
  comparator: (currentlyComparingRow: TableData) => boolean
) => {
  const handledTableData = tableData.map((row: TableData) => {
    if (comparator(row)) {
      selectable && selectable.set(row, nextState);
      return {
        ...row,
        isSelected: nextState,
      };
    }
    return row;
  });
  return handledTableData;
};

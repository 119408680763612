import { BaseAction } from "../../typescript/redux";
import TYPES from "../types/auth";

export interface AuthAction extends BaseAction {
  payload?: MockUser;
}

export const login: () => AuthAction = () => ({
  type: TYPES.LOGIN,
});

export const success: (payload: MockUser) => AuthAction = (
  payload: MockUser
) => ({
  type: TYPES.SUCCESS,
  payload,
});

export const fail: () => AuthAction = () => ({
  type: TYPES.FAIL,
});

export const logout: () => AuthAction = () => ({
  type: TYPES.LOGOUT,
});

import React from "react";
import PageTitle from "../../_molecules/PageTitle";
import { Timesheets } from "@mzt-monorepo/mzt-atomic";
import {
  Container,
  Submit,
  SubmitContainer,
  SuperContainer,
} from "./styled-components";
import TimeSheetsList from "../../_molecules/TimeSheetList";
import TimesheetHeader from "../../_atoms/TimesheetHeader";
import { Moment } from "moment";
import moment from "moment";

enum ButtonLabel {
  SUBMIT = "SUBMIT",
  EDIT = "EDIT",
}

interface Props {
  mock: {
    month: Moment;
    project: string;
    status: string;
    limitDate: string;
    manager: string;
    cardDays: number[];
  }[];
  handleShowModal: (state: boolean) => void;
}

const TimeSheetTemplate = (props: Props) => {
  const [buttonLabel, setButtonLabel] = React.useState({
    label: ButtonLabel.SUBMIT,
    enabled: false,
  });

  const handleItemClick = React.useCallback(
    (label) => {
      const newState = { ...buttonLabel };
      newState.label = label;
      if (label === "Submited" || label === "Approved") {
        newState.enabled = true;
        newState.label = ButtonLabel.EDIT;
      } else {
        newState.enabled = true;
        newState.label = ButtonLabel.SUBMIT;
      }
      setButtonLabel(newState);
    },
    [buttonLabel]
  );

  return (
    <SuperContainer>
      <Container>
        <PageTitle icon={Timesheets} title="TIMESHEETS" />
        <TimesheetHeader />
        <TimeSheetsList
          mock={props.mock}
          handleItemClick={handleItemClick}
          handleShowModal={props.handleShowModal}
        />
      </Container>
      <SubmitContainer>
        <Submit disabled={!buttonLabel.enabled}>{buttonLabel.label}</Submit>
      </SubmitContainer>
    </SuperContainer>
  );
};

export default React.memo(TimeSheetTemplate);

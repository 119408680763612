import React, { useCallback } from "react";
import { Avatar, LabeledLogo, Settings } from "@mzt-monorepo/mzt-atomic";
import {
  Container,
  Header,
  Footer,
  Actions,
  Logout,
  SettingsRedirect,
} from './styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from './../../../redux/selectors/auth';
import { logout } from './../../../redux/actions/auth';
import Navbar from '../Navbar';
import { PATHS } from './../../../routes/types';

export const Sidebar = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Container aria-label="Sidebar">
      <Header>
        <Avatar src={user.picture} />
        <h2 aria-label="User name">{user.displayName}</h2>
        <Actions>
          <SettingsRedirect to={PATHS.SETTINGS}>
            <Settings />
          </SettingsRedirect>
          <Logout onClick={handleLogout} />
        </Actions>
      </Header>
      <Navbar />
      <Footer>
        <LabeledLogo
          label="MOZANTECH"
          position="bottom"
          color="white"
          size={32}
        />
      </Footer>
    </Container>
  );
};

export default Sidebar;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Business = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.667}
    height={33}
    viewBox="0 0 36.667 33"
    {...props}
  >
    <path
      d="M18.333 7.333V0H0v33h36.667V7.333zm-11 22H3.667v-3.666h3.666zm0-7.333H3.667v-3.667h3.666zm0-7.333H3.667V11h3.666zm0-7.333H3.667V3.667h3.666zm7.333 22H11v-3.667h3.667zm0-7.333H11v-3.668h3.667zm0-7.333H11V11h3.667zm0-7.333H11V3.667h3.667zM33 29.333H18.333v-3.666H22V22h-3.667v-3.667H22v-3.666h-3.667V11H33zm-3.667-14.666h-3.666v3.667h3.667zm0 7.333h-3.666v3.667h3.667z"
      fill="#fa6f39"
    />
  </svg>
);

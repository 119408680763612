import React, { AnchorHTMLAttributes } from "react";
import { Wrapper } from "./styled-components";

export const Anchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <Wrapper {...props} rel="noopener noreferrer" role="link">
    {props.children}
  </Wrapper>
);

export default Anchor;

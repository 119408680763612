import React, { useCallback, useEffect, useState } from "react";
import { Container, Wrapper } from "./styled-components";

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  defaultChecked?: boolean;
}

/**
 * We are using the Checkmark as a background image because building the Checkmark as an element and hiding/showing it
 * was interfering with the components consistent behavior
 * @param param0
 */
export const Checkbox = ({
  defaultChecked,
  checked = false,
  ...props
}: CheckboxProps) => {
  const [isChecked, setChecked] = useState<boolean>(defaultChecked || false);

  /**
   * Listens to any changes on the checked prop and if it changes, the component will also change with it.
   * This allows us to have always the same value between the received prop and the local state
   */
  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  /**
   * Switches the checkbox checked state
   */
  const handleClick = useCallback(() => {
    setChecked(!isChecked);
  }, [isChecked]);

  return (
    <Container $isChecked={isChecked}>
      <Wrapper
        onClick={handleClick}
        checked={isChecked}
        {...props}
        type="checkbox"
      />
    </Container>
  );
};

export default React.memo(Checkbox);

import { Groups } from "@mzt-monorepo/mzt-atomic";
import { fail, start, success } from "./../../../redux/actions/users";
import { getUsers } from "./../../../services/users";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import PageTitle from "../../_molecules/PageTitle";
import EmployeeList from "../../_organisms/EmployeeList";
import { Container } from "./styled-components";

const fetchUsers = async (dispatch: Dispatch) => {
  dispatch(start());

  try {
    const res = await getUsers();
    dispatch(success(res.data));
  } catch (err) {
    dispatch(fail());
  }
};

const Employees = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUsers(dispatch);
  }, [dispatch]);

  return (
    <Container>
      <PageTitle icon={Groups} title="Employees" />
      <EmployeeList />
    </Container>
  );
};

export default Employees;

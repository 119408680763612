import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.section`
  border-bottom: 2px solid ${getColor("orange")};
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
`;

export const Block = styled.div`
  margin: 0;
  margin-right: 75px;
`;

import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  color: ${getColor("white")};
  align-items: center;
  margin: 24px 0;

  svg {
    width: 20px;
  }
`;

export const Title = styled.h1`
  padding: 0 24px;
  box-sizing: border-box;
  font-size: 24px;
`;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Analytics = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    viewBox="0 0 44 44"
    {...props}
  >
    <path d="M0 0h44v44H0z" fill="none" />
    <path
      d="M36.778 3H7.222A4.235 4.235 0 003 7.222v29.556A4.235 4.235 0 007.222 41h29.556A4.235 4.235 0 0041 36.778V7.222A4.235 4.235 0 0036.778 3zM15.667 32.556h-4.223V22h4.222zm8.444 0h-4.222v-6.334h4.222zm0-10.556h-4.222v-4.222h4.222zm8.444 10.556h-4.222V11.444h4.222z"
      fill="#fa6f39"
    />
  </svg>
);

import { MZTConf, Env } from "@mzt-monorepo/mzt-intranet-types";

let conf: MZTConf = {
  env: Env.PROD,
  service: "",
};

export const mutateConf = (environment): MZTConf => {
  if (environment.production) {
    conf = {
      env: Env.PROD,
      service: `https://${Env.PROD}.service-intranet.mozantech.com/`,
    };
    return;
  }
  conf = {
    env: Env.BETA,
    service: `https://${Env.BETA}.service-intranet.mozantech.com/`,
  };
};

export const mztConf = () => conf;

import { Moment } from "moment";
import React from "react";
import { Wrapper, Label } from "./styled-components";

interface VacationCalendarTimeoffProps {
  date: Moment;
  children: string;
}

export const VacationCalendarTimeoff = ({
  date,
  children,
}: VacationCalendarTimeoffProps) => (
  <Wrapper>
    {date.format("DD MMM")}
    <Label>{children}</Label>
  </Wrapper>
);

export default VacationCalendarTimeoff;

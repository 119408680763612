import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";
import Background from "../../../assets/daysOff_bg.svg";

export const Wrapper = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 12px 0;
  border-radius: 32px;
  height: 96px;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${getColor("darkBlue")};
  font-size: 18px;
  padding: 1.2rem 1rem 0.5rem 1rem;
  box-sizing: border-box;
`;

export const Label = styled.p`
  font-size: 12px;
  text-align: center;
`;

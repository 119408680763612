import { Button, getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled(Button)`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  padding: 0;
  transition: opacity 0.2s ease-in-out;
  box-shadow: 0px 3px 6px ${getColor("black", 30)};
  margin: 1rem 0.5rem;

  &:active {
    opacity: 0.8;
  }
`;

import { Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import { Wrapper, TextareaProps } from "./styled-components";

export const TextArea = ({
  resize = "both",
  roundness = Roundness.SEMI_ROUND,
  children,
  ...props
}: TextareaProps) => (
  <Wrapper resize={resize} roundness={roundness} {...props}>
    {children}
  </Wrapper>
);

import { getColor } from "@mzt-monorepo/mzt-atomic";
import { css } from "styled-components";
import SimpleBackground from "../assets/background-simple.svg";
import Background from "../assets/background.svg";

export const injectBackground = (inject?: boolean) => css`
  background-image: url(${!inject ? SimpleBackground : Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const injectContentStyle = css`
  box-shadow: 0px 0px 5px 3px ${getColor("purple", 40)};
  height: 726px;
`;

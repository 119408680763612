import React from "react";
import { Input, InputVariant } from "@mzt-monorepo/mzt-atomic";

import { Container, Label } from "./styled-components";

export type InputType = "email" | "password";
export interface LoginInputProps {
  label: string;
  type: InputType;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  variant?: InputVariant;
}

export const LoginInput = ({
  label,
  type,
  placeholder = "",
  onChange,
  value,
  name,
  variant = "secondary",
}: LoginInputProps) => (
  <Container>
    <Label htmlFor={`${label}_${name}`}>{label}</Label>
    <Input
      id={`${label}_${name}`}
      autoComplete="off"
      isMinimal
      isHollow
      variant={variant}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
    />
  </Container>
);

export default LoginInput;

import React from "react";
import { LabelContainer, TopLabel } from "./styled-components";

const TimesheetHeader = () => {
  return (
    <LabelContainer>
      <TopLabel>MONTH</TopLabel>
      <TopLabel>PROJECT</TopLabel>
      <TopLabel>STATUS</TopLabel>
      <TopLabel>LIMIT DATE</TopLabel>
      <TopLabel>MANAGER</TopLabel>
    </LabelContainer>
  );
};

export default React.memo(TimesheetHeader);

import { ColorScheme, Roundness } from "@mzt-monorepo/mzt-types";
import styled, { css } from "styled-components";
import { Search, Cross } from "../../../_static/icons";
import { getRoundness } from "../../../_static/styles";

interface WrapperProps {
  roundness: Roundness;
  borderless: boolean;
  colorScheme: ColorScheme;
}

const computeWrapperRules = ({ roundness, borderless }: WrapperProps) => css`
  ${borderless && `border: none;`}
  ${getRoundness(roundness)}
`;

export const Wrapper = styled.div<WrapperProps>`
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  border: 1px solid black;
  color: ${({ colorScheme }) => colorScheme.text};

  svg {
    fill: ${({ colorScheme }) => colorScheme.secondary};
  }

  ${computeWrapperRules};
`;

export const SearchInput = styled.input`
  font: inherit;
  color: inherit;
  box-sizing: border-box;
  outline: none;
  border: none;
  flex-grow: 1;
  background: none;
  height: 100%;
  padding: 8px 0;
  min-width: 0;
`;

export const SearchIcon = styled(Search)`
  min-width: 8px;
  width: 16px;
  height: 16px;
`;

export const CancelIcon = styled(Cross)`
  cursor: pointer;
  min-width: 8px;
  width: 8px;
  height: 8px;
`;

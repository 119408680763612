import React from "react";
import { Wrapper } from "./styled-components";
import { Search } from "./search";
import { FileInput } from "./file";
import { Roundness } from "@mzt-monorepo/mzt-types";

export type InputVariant = "primary" | "secondary";

/* eslint-disable-next-line */
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isMinimal?: boolean;
  roundness?: Roundness;
  isHollow?: boolean;
  variant?: InputVariant;
}

interface InputInterface extends React.FC<InputProps> {
  Search: typeof Search;
  File: typeof FileInput;
}

export const Input: InputInterface = ({
  isMinimal = false,
  roundness = Roundness.FLAT,
  isHollow = false,
  variant = "primary",
  ...props
}) => (
  <Wrapper
    {...props}
    variant={variant}
    isHollow={isHollow}
    isMinimal={isMinimal}
    roundness={roundness}
  />
);

Input.Search = Search;
Input.File = FileInput;

export default Input;
export { InputFilesObject } from "./file";

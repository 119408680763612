import moment, { Moment } from "moment";
import React, { useCallback, useMemo, useState } from "react";

interface useDateIntervalReturn {
  state: [Moment, Moment];
  handlers: {
    from: (e: React.ChangeEvent<HTMLInputElement>) => void;
    to: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  min?: string;
  max?: string;
}

type useDateIntervalType = (
  initialState?: [Moment, Moment],
  minmax?: [string, string]
) => useDateIntervalReturn;

export const useDateInterval: useDateIntervalType = (
  initalState = [null, null],
  minmax = null
) => {
  const [state, setState] = useState<[Moment, Moment]>(() => {
    if (minmax) {
      return [moment(minmax[0]), moment(minmax[1])];
    }
    return initalState;
  });

  const fromHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setState([moment(e.target.value), state[1]]);
    },
    [state]
  );

  const toHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const date = moment(e.target.value);
      if (date.isBefore(state[0])) return;
      setState([state[0], date]);
    },
    [state]
  );

  return useMemo(
    () => ({
      state,
      handlers: {
        from: fromHandler,
        to: toHandler,
      },
      min: minmax && minmax[0],
      max: minmax && minmax[1],
    }),
    [state, fromHandler, toHandler, minmax]
  );
};

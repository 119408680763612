import { Table, getColor } from "@mzt-monorepo/mzt-atomic";
import {
  UseTableSelectionReturn,
  TableColumn,
  TableData,
  SortConfig,
  PaginationConfigPosition,
} from "@mzt-monorepo/mzt-types";
import { TableWrapper, Wrapper } from "./styled-components";
import React from "react";

export interface TimesheetAprovalTableProps {
  rows: TableData[];
  columns: TableColumn[];
  selectMethods?: UseTableSelectionReturn;
  sortConfig?: SortConfig | string;
}

const PAGINATION_CONFIG = {
  position: PaginationConfigPosition.BOTTOM,
  numberRows: 8,
  color: getColor("white"),
};

const TimesheetApprovalTable: React.FC<TimesheetAprovalTableProps> = ({
  rows,
  columns,
  selectMethods = null,
  sortConfig,
}) => (
  <Wrapper>
    <TableWrapper>
      <Table
        columns={columns}
        data={rows}
        selectable={selectMethods}
        sortBy={sortConfig}
        pagination={rows.length > 10 ? PAGINATION_CONFIG : null}
      />
    </TableWrapper>
  </Wrapper>
);

export default TimesheetApprovalTable;

import styled, { css } from "styled-components";
import { Calendar, Cross, getColor } from "@mzt-monorepo/mzt-atomic";

export enum VacationSelected {
  FROM = "from",
  TO = "to",
  NONE = "none",
}

interface VacationDayProps {
  $isEditing: boolean;
  $selected: VacationSelected;
  $isBetween: boolean;
  $hasFullInterval: boolean;
}

const line = css`
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 500%;
    background-color: ${getColor("yellow", 70)};
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
`;

const selected = css`
  background-color: ${getColor("yellow")};
  ${line}
`;

const selectedFrom = css`
  ${selected}
  &::after {
    left: 50%;
  }
`;

const selectedTo = css`
  ${selected}
  &::after {
    right: 50%;
  }
`;

const between = css`
  font-size: 16px;
  padding: 0;
  ${line}
  &::after {
    left: -200%;
  }
`;

const editing = css`
  cursor: pointer;
  &:hover {
    background-color: ${getColor("yellow")};
  }
`;

const disableLine = css`
  &::after {
    opacity: 0;
  }
`;

const computeSelected = (selected: VacationSelected) => {
  switch (selected) {
    case VacationSelected.FROM:
      return selectedFrom;
    case VacationSelected.TO:
      return selectedTo;
    default:
      return "";
  }
};

const computeVactionDayProps = ({
  $isEditing,
  $isBetween,
  $selected,
  $hasFullInterval,
}: VacationDayProps) => css`
  ${$isEditing && editing}
  ${$isBetween && $hasFullInterval && between}
  ${computeSelected($selected)}
  ${!$hasFullInterval && disableLine}
`;

export const Wrapper = styled(Calendar)``;

export const VacationDay = styled.div<VacationDayProps>`
  transition: background-color 0.2s ease-in-out;
  border-radius: 50px;
  padding: 8px;

  ${computeVactionDayProps}
`;

export const DismissSelection = styled(Cross)`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 0;
  right: 0;
  cursor: pointer;
`;

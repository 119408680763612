import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { mutateConf } from "@mzt-monorepo/mzt-intranet-conf";
import { renderRoutes } from "../routes";
import { Container } from "./styled-components";
import { environment } from "../environments/environment";

export const App = () => {
  React.useLayoutEffect(() => {
    mutateConf(environment);
  }, []);
  return (
    <Container>
      <Router>{renderRoutes()}</Router>
    </Container>
  );
};

export default App;

import styled from "styled-components";
import { getColor } from "../../_static/colors";

export const Wrapper = styled.a`
  color: ${getColor("orange")};
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-bottom: 1px solid;
  }
`;

import { ColorScheme, Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import { Input } from "../../../_atoms/input";
import { Wrapper, Container, Text, DropdownArrow } from "./styled-components";
import { SearchObject } from "../types";

interface SelectHeaderProps {
  hasSearch: boolean;
  search: SearchObject;
  expanded: boolean;
  defaultValue: string;
  currentValue: string | null;
  roundness: Roundness;
  colorScheme: ColorScheme;
  toggleExpand: () => void;
}

export const SelectHeader = ({
  search,
  currentValue,
  defaultValue,
  expanded,
  hasSearch,
  roundness,
  colorScheme,
  toggleExpand,
}: SelectHeaderProps) => (
  <Wrapper roundness={roundness} colorScheme={colorScheme}>
    <Container onClick={toggleExpand}>
      {hasSearch && expanded ? (
        <Input.Search
          roundness={roundness}
          onChange={search.handler}
          value={search.value}
          placeholder="Search"
          borderless
          cancelSearch={search.reset}
          onClick={(e) => e.stopPropagation()}
          colorScheme={colorScheme}
        />
      ) : (
        <Text>{currentValue || defaultValue}</Text>
      )}
    </Container>
    <DropdownArrow
      $expanded={expanded || false}
      onClick={toggleExpand}
      fill={colorScheme.secondary}
    />
  </Wrapper>
);

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Groups = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    viewBox="0 0 44 44"
    {...props}
  >
    <path fill="none" d="M0 0h44v44H0z" />
    <path
      d="M22 23.375a19.453 19.453 0 017.773 1.65 5.471 5.471 0 013.227 5V33H11v-2.952a5.433 5.433 0 013.227-5A19.141 19.141 0 0122 23.375zm-14.667.458a3.667 3.667 0 10-3.667-3.667 3.677 3.677 0 003.667 3.667zm2.072 2.017a12.8 12.8 0 00-2.072-.183 12.743 12.743 0 00-5.1 1.063A3.687 3.687 0 000 30.122V33h8.25v-2.952a8.248 8.248 0 011.155-4.198zm27.262-2.017A3.667 3.667 0 1033 20.167a3.677 3.677 0 003.667 3.666zM44 30.122a3.687 3.687 0 00-2.237-3.392 12.463 12.463 0 00-7.168-.88 8.248 8.248 0 011.155 4.2V33H44zM22 11a5.5 5.5 0 11-5.5 5.5A5.493 5.493 0 0122 11z"
      fill="#fa6f39"
    />
  </svg>
);

import { getColor, Input, Label, Timesheets } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalDatesLabel = styled(Label)`
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ModalInput = styled(Input)`
  height: 40px;
  width: 154px;
`;

export const Icon = styled(Timesheets)`
  height: 20px;
  path {
    fill: ${getColor("black")};
  }
`;

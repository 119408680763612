import React, { useCallback, useState } from "react";
import { Button, LabeledLogo, Loading } from "@mzt-monorepo/mzt-atomic";
import { Credentials } from "@mzt-monorepo/mzt-intranet-types";
import { Container, Content, Footer, InputGroup } from "./styled-components";
import LoginInput from "../LoginInput";
import LoginRecovery from "../LoginRecovery";
import { Props } from "./types";

const DEFAULT_CREDENTIALS: Credentials = {
  email: "",
  password: "",
};

export const LoginForm = ({ handleLogin, isProcessingLogin }: Props) => {
  const [credentials, setCredentials] = useState<Credentials>(
    DEFAULT_CREDENTIALS
  );
  const onLoginClick = useCallback(() => {
    handleLogin(credentials);
  }, [credentials, handleLogin]);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCredentials({
        ...credentials,
        [event.target.name]: event.target.value,
      });
    },
    [credentials]
  );
  return (
    <Container
      variant="alternative"
      aria-label="Login form"
      aria-roledescription="form"
    >
      <Content>
        <LabeledLogo position="bottom" size={32} color="white" />
        <h1>WELCOME</h1>
        <InputGroup>
          <LoginInput
            label="Login"
            placeholder="example@mozantech.com"
            name="email"
            type="email"
            onChange={handleChange}
            value={credentials.email}
          />
          <LoginInput
            label="Password"
            type="password"
            placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
            name="password"
            onChange={handleChange}
            value={credentials.password}
          />
        </InputGroup>
        <Button isHollow onClick={onLoginClick}>
          {!isProcessingLogin ? (
            "Login"
          ) : (
            <Loading style={{ height: "16px", width: "16px" }} />
          )}
        </Button>
        <LoginRecovery />
      </Content>
      <Footer>@ MOZANTECH INTRANET ALL RIGTHS RESERVED</Footer>
    </Container>
  );
};

export default LoginForm;

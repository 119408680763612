import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Caret = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-1 131.003 644 379.994"
    width={640}
    height={375.99}
    {...props}
  >
    <defs>
      <path
        d="M629.78 212.98c6.59-6.59 10.22-15.38 10.22-24.76 0-9.37-3.63-18.17-10.22-24.75-2.1-2.1-18.88-18.88-20.97-20.98-13.67-13.65-35.87-13.65-49.52 0-15.94 15.95-95.66 95.67-239.16 239.16L80.71 142.23C74.11 135.64 65.33 132 55.96 132c-9.39 0-18.17 3.64-24.77 10.23-2.1 2.09-18.87 18.87-20.97 20.97C3.63 169.79 0 178.58 0 187.96c0 9.37 3.63 18.17 10.22 24.75l285.07 285.08c6.61 6.6 15.44 10.23 24.83 10.21 9.42.02 18.24-3.61 24.85-10.21l284.81-284.81z"
        id="prefix__a"
      />
    </defs>
    <use xlinkHref="#prefix__a" />
    <use
      xlinkHref="#prefix__a"
      fillOpacity={0}
      stroke="#000"
      strokeOpacity={0}
    />
  </svg>
);

import React from "react";
import { Container, VacationTag } from "./styled-components";

export const VacationCalendarTags = () => (
  <Container>
    <VacationTag variant="info" light>
      Public Holiday
    </VacationTag>
    <VacationTag variant="success" light>
      Approved
    </VacationTag>
    <VacationTag variant="alert" light>
      Pending
    </VacationTag>
    <VacationTag variant="danger" light>
      MZT Event
    </VacationTag>
  </Container>
);

export default VacationCalendarTags;

export interface RouteType {
  path: string;
  component: () => React.ReactElement | null;
  exact: boolean;
  isProtected: boolean;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
}

export const PATHS = {
  HOME: "/",
  LOGIN: "/login",
  VACATIONS: "/vacations",
  TIMESHEETS: "/timesheets",
  EXPENSES: "/expenses",
  LEARNING: "/learning",
  PARTNERS: "/partners",
  TICKETS: "/tickets",
  FEEDBACK: "/feedback",
  EMPLOYEES: "/employees",
  SETTINGS: "/settings",
  TIMESHEET_APPROVALS: "/management/timesheets"
};

import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div``;

export const TableWrapper = styled.div`
  margin: 16px auto;
  color: ${getColor("white")};

  table {
    margin: 8px;
    background-color: ${getColor("white")};

    td {
      height: 60px;
    }
  }
`;

import React, { useCallback, useState } from "react";
import {
  Wrapper,
  Header,
  Month,
  MonthCycle,
  Left,
  Right,
  Days,
  DayOfMonth,
  DayOfWeek,
} from "./styled-components";
import moment, { Moment } from "moment";
import { cloneMoment } from "@mzt-monorepo/mzt-utils";

export interface CalendarProps {
  onDayRender?: (day: Moment) => React.ReactNode;
  value?: Moment;
  setValue?: (value: Moment) => void;
  header?: boolean;
  mini?: boolean;
  className?: string;
  viewOnly?: boolean;
}

const renderDaysOfWeek = (mini: boolean) =>
  moment
    .updateLocale("en", {
      week: {
        dow: 1,
      },
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .weekdays(true) // ATTENTION!! This is throwing an error but on Moment's documentation this is the correct usage of weekdays()
    .map((value: string, index: number) => (
      <DayOfWeek key={index} $mini={mini}>
        {value.substring(0, 3)}
      </DayOfWeek>
    ));

const renderDaysOfMonth = (
  date: Moment,
  mini: boolean,
  onDayRender?: (day: Moment) => React.ReactNode
) => {
  const start = date.clone().startOf("month").startOf("isoWeek");
  const end = date.clone().endOf("month").endOf("isoWeek");
  const now = moment();

  const days: Moment[] = [];

  while (start.isBefore(end)) {
    days.push(cloneMoment(start));
    start.add(1, "day");
  }

  return days.map((day: Moment, index: number) => (
    <DayOfMonth
      key={index}
      $isDisabled={day.month() !== date.month()}
      $isCurrent={now.isSame(day, "date")}
      $mini={mini}
    >
      <span>{onDayRender ? onDayRender(day) : day.format("DD")}</span>
    </DayOfMonth>
  ));
};

export const Calendar = ({
  onDayRender,
  value,
  setValue,
  header = true,
  mini = false,
  className,
  viewOnly = false,
}: CalendarProps) => {
  const [date, setDate] = useState<Moment>(moment());

  const controlledDate = value || date;
  const setControlledDate = setValue || setDate;

  const addMonth = useCallback(() => {
    const newDate = cloneMoment(controlledDate, 1, "M");
    setControlledDate(newDate);
  }, [controlledDate, setControlledDate]);

  const subtractMonth = useCallback(() => {
    const newDate = cloneMoment(controlledDate, -1, "M");
    setControlledDate(newDate);
  }, [controlledDate, setControlledDate]);

  return (
    <Wrapper aria-label="Calendar" className={className}>
      {header && (
        <Header $mini={mini}>
          <Month>
            <b>{controlledDate.format(mini ? "MMM " : "MMMM ")}</b>
            {controlledDate.format(mini ? " YY" : "YYYY ")}
          </Month>
          <MonthCycle $mini={mini}>
            {!viewOnly && (
              <>
                <Left onClick={subtractMonth} />
                <Right onClick={addMonth} />
              </>
            )}
          </MonthCycle>
        </Header>
      )}
      <Days>
        {renderDaysOfWeek(mini)}
        {renderDaysOfMonth(controlledDate, mini, onDayRender)}
      </Days>
    </Wrapper>
  );
};

export default React.memo(Calendar);

import { getColor, Font } from "@mzt-monorepo/mzt-atomic";
import Main from "../../../wrappers/Main";
import styled from "styled-components";

export const Container = styled(Main)`
  font-family: ${Font.MONUMENT};
  color: ${getColor("white")};
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

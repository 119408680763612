import { BackgroundImage } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Container = styled.main`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-image: url(${BackgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

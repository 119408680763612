import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Help = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.667}
    height={36.667}
    viewBox="0 0 36.667 36.667"
    {...props}
  >
    <path
      d="M16.5 29.333h3.667v-3.666H16.5zM18.333 0a18.333 18.333 0 1018.334 18.333A18.34 18.34 0 0018.333 0zm0 33A14.667 14.667 0 1133 18.333 14.686 14.686 0 0118.333 33zm0-25.667A7.331 7.331 0 0011 14.667h3.667a3.667 3.667 0 117.333 0c0 3.667-5.5 3.208-5.5 9.167h3.667c0-4.125 5.5-4.583 5.5-9.167a7.331 7.331 0 00-7.334-7.334z"
      fill="#fa6f39"
    />
  </svg>
);

import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../../../redux/selectors/auth";
import { PATHS } from "./../../../routes/types";
import { Container, Content } from "./styled-components";
import Sidebar from "../../_organisms/Sidebar";

interface Props {
  children: React.ReactElement;
}

const Protected: (props: Props) => React.ReactElement = ({
  children,
}: Props) => {
  const isLoggedIn = useSelector(isUserLoggedIn);

  if (!isLoggedIn) return <Redirect to={PATHS.LOGIN} />;

  return (
    <Container>
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  );
};

export default Protected;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TrashBin = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <g id="delete-24px" opacity="0.41">
      <path
        id="Path_1528"
        data-name="Path 1528"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Path_1529"
        data-name="Path 1529"
        d="M6,19a2.006,2.006,0,0,0,2,2h8a2.006,2.006,0,0,0,2-2V7H6ZM19,4H15.5l-1-1h-5l-1,1H5V6H19Z"
      />
    </g>
  </svg>
);

import { Roundness } from '@mzt-monorepo/mzt-types';
import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../_static/colors';
import { getRoundness } from '../../_static/styles';

export type Resizable = 'both' | 'horizontal' | 'vertical';

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  resize?: Resizable;
  roundness?: Roundness;
}

export const Wrapper = styled.textarea<TextareaProps>`
  width: 100%;
  height: 136px;
  box-sizing: border-box;
  outline: none;
  border: solid 1px ${getColor('black')};
  padding: 8px;
  font: inherit;
  min-height: 80px;

  ${({ resize, roundness }: TextareaProps) => `
    ${getRoundness(roundness)};
    resize: ${resize};
`};
`;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CheckFlat = (props: Record<string, any>) => (
  <svg
    id="done-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path id="Path_1526" data-name="Path 1526" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_1527"
      data-name="Path 1527"
      d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
    />
  </svg>
);

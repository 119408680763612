import { getColor } from "@mzt-monorepo/mzt-atomic";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  $isActive: boolean;
}

export const Container = styled(Link)<Props>`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: ${({ $isActive }) =>
    $isActive ? getColor("orange") : getColor("white")};
  text-decoration: none;

  &:hover {
    background-color: ${getColor("orange", 10)};
    color: ${getColor("orange")};
  }
`;

import { Timesheets } from "@mzt-monorepo/mzt-atomic";
import React, { useEffect } from "react";
import { fail, start, success } from "./../../../redux/actions/timesheets";
import { Dispatch } from "redux";
import PageTitle from "../../_molecules/PageTitle";
import TimesheetApprovalData from "../../_organisms/TimesheetAprovalData";
import { Container } from "./styled-components";
import { getTimesheets } from "../../../services/timesheets";
import { useDispatch } from "react-redux";

const fetchTimesheets = async (dispatch: Dispatch) => {
  dispatch(start());

  try {
    const res = await getTimesheets();
    dispatch(success(res.data));
  } catch (err) {
    dispatch(fail());
  }
};

const TimesheetApprovals = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTimesheets(dispatch);
  }, [dispatch]);

  return (
    <Container>
      <PageTitle icon={Timesheets} title="Timesheet Approval" />
      <TimesheetApprovalData />
    </Container>
  );
};

export default TimesheetApprovals;

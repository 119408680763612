import { Card, Edit, File, getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

const BLUE = getColor("darkBlue");

export const Container = styled(Card)`
  height: 400px;
  display: flex;
  flex-direction: column;
  color: ${BLUE};
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 8px 0;
`;

export const Tag = styled.div`
  margin: 0 -10px;
  background-color: ${BLUE};
  color: ${getColor("white")};
  padding: 4px 32px;
  border-radius: 4px;
`;

export const GrayText = styled.p`
  color: ${getColor("black")};
  opacity: 0.3;
  margin: 4px 0;
`;

export const AvatarSetting = styled.div`
  position: relative;
  margin: 16px 0;
  display: flex;
  justify-content: center;

  &:hover {
    svg {
      opacity: 0.3;
    }
  }
`;

export const EditAvatar = styled(Edit)`
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  fill: ${getColor("black")};
  opacity: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:active {
    fill: ${getColor("orange")};
    opacity: 1 !important;
  }
`;

export const Fullname = styled.h4`
  text-align: center;
  margin: 4px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PersonalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  margin: 8px 0;
`;

export const RoleTag = styled.div`
  display: flex;
  width: 200px;
  margin: 0 auto;
  padding: 4px 8px;
  text-align: center;
  border-radius: 8px;
  white-space: nowrap;
  background-color: ${BLUE};
  color: ${getColor("white")};
  font-size: 12px;

  p {
    margin: 0;
    text-overflow: ellipsis;
    width: 50%;
    overflow: hidden;
  }
`;

export const Documents = styled.div`
  color: ${BLUE};
  display: flex;
  font-size: 12px;
  flex-direction: column;
  flex: 1;
  margin: 4px 0;
  text-align: center;

  h5 {
    margin: 4px;
    flex-grow: 1;
  }
`;

export const DocumentsRow = styled.div`
  display: flex;
`;

export const DocumentBlock = styled.div`
  font-size: 10px;
  flex: 1;
  svg {
    fill: ${BLUE};
  }
`;

export const Document = styled(File)`
  cursor: pointer;
  height: 24px;
  width: 24px;
  fill: ${BLUE};
  transition: fill 0.2s ease-in-out;

  &:active {
    fill: ${getColor("orange")};
  }
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { accountService } from "@mzt-monorepo/mzt-intranet-api";
import { Credentials } from "@mzt-monorepo/mzt-intranet-types";
import LoginForm from "../../_molecules/LoginForm";
import { PATHS } from "../../../routes/types";
import * as actions from "../../../redux/actions/auth";
import { isLoadingLogin } from "../../../redux/selectors/auth";
import { USER as MockUser } from "../../../services/auth";

const Login = () => {
  const isProcessingLogin = useSelector(isLoadingLogin);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogin = React.useCallback((credentials: Credentials) => {
    dispatch(actions.login());
    accountService
      .login(credentials)
      .then(({ data }) => {
        // TODO: Remove log
        console.log(data);
        dispatch(actions.success(MockUser));
        history.push(PATHS.HOME);
      })
      .catch(() => {
        dispatch(actions.fail());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LoginForm
      handleLogin={handleLogin}
      isProcessingLogin={isProcessingLogin}
    />
  );
};

export default React.memo(Login);

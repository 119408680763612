import { ColorScheme } from "@mzt-monorepo/mzt-types";
import { get } from "lodash";

export type ColorTypes =
  | "blue"
  | "darkBlue"
  | "gray"
  | "green"
  | "orange"
  | "purple"
  | "white"
  | "yellow"
  | "black"
  | "violet"
  | "red"
  | "middleDarkBlue";

const BASE: Record<ColorTypes, string> = {
  blue: "#107EB7",
  darkBlue: "#1F0065",
  middleDarkBlue: "#2A008B",
  gray: "#707070",
  green: "#087401",
  orange: "#FA6F39",
  violet: "#74016C",
  white: "#FFFFFF",
  yellow: "#FFC917",
  black: "#000000",
  purple: "#230061",
  red: "#C22F3E",
};

export const getColor = (color: ColorTypes, alpha?: number | string) =>
  `${get(BASE, `[${color}]`)}${alpha || ""}`;

export const generateColorScheme = (scheme: ColorScheme) =>
  scheme &&
  `
background: ${scheme.background};
color: ${scheme.text};
border-color: ${scheme.secondary};
`;

export const DEFAULT_COLOR_SCHEME: ColorScheme = {
  background: getColor("white"),
  secondary: getColor("black"),
  text: getColor("black"),
};

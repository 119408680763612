import { Roundness } from "@mzt-monorepo/mzt-types";
import React from "react";
import { ButtonsContainer, Button } from "./styled-components";

export interface TableViewProps {
  disabled?: boolean;
  acceptCallback: () => void;
}

export const TableViewButtons = ({ disabled = false, acceptCallback }) => (
  <ButtonsContainer>
    <Button
      roundness={Roundness.ROUND}
      disabled={disabled}
      onClick={acceptCallback}
    >
      Accept all
    </Button>
  </ButtonsContainer>
);

export default TableViewButtons;

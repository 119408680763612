import React from "react";
import {
  AddTimeOff,
  ArrowButton,
  CardContainer,
  Container,
  TimeSheetContainer,
  TimeSheetLabel,
} from "./styled-components";
import { Expand } from "@mzt-monorepo/mzt-atomic";
import TimesheetsCard, { DescriptionOfDays } from "../TimesheetsCard";
import { Moment } from "moment";

interface Props {
  month: Moment;
  project: string;
  status: string;
  limitDate: string;
  manager: string;
  cardDays: number[];
  handleItemClick: (state: string) => void;
  handleShowModal: (state: boolean) => void;
}

const TimesheetItem = (props: Props) => {
  const [showCard, setShowCard] = React.useState(false);
  return (
    <Container>
      <TimeSheetContainer>
        <TimeSheetLabel>{props.month.format("MMMM")}</TimeSheetLabel>
        <TimeSheetLabel>{props.project}</TimeSheetLabel>
        <TimeSheetLabel>{props.status}</TimeSheetLabel>
        <TimeSheetLabel>{props.limitDate}</TimeSheetLabel>
        <TimeSheetLabel>
          {props.manager}
          <ArrowButton
            onClick={() => {
              props.handleItemClick(props.status);
              setShowCard(showCard ? false : true);
            }}
          >
            <Expand />
          </ArrowButton>
        </TimeSheetLabel>
      </TimeSheetContainer>
      {showCard && (
        <CardContainer>
          <TimesheetsCard
            title={DescriptionOfDays.WORKED}
            numberOfDays={String(props.cardDays[0])}
          />
          <TimesheetsCard
            title={DescriptionOfDays.VACATIONS}
            numberOfDays={String(props.cardDays[1])}
          />
          <TimesheetsCard
            title={DescriptionOfDays.MEDICAL}
            numberOfDays={String(props.cardDays[2])}
          />
        </CardContainer>
      )}
      {showCard && (
        <AddTimeOff
          disabled={props.status === "Approved" ? true : false}
          onClick={() => {
            props.handleShowModal(true);
          }}
        >
          ADD TIME OFF
        </AddTimeOff>
      )}
    </Container>
  );
};

export default React.memo(TimesheetItem);

import React from "react";
import { Container, Title } from "./styled-components";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: (props?: any) => JSX.Element;
  title: string;
}

const PageTitle = ({ icon: Icon, title }: Props) => (
  <Container aria-label="Page title">
    <Icon />
    <Title>{title}</Title>
  </Container>
);

export default PageTitle;

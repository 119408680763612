import { createGlobalStyle } from "styled-components";
import { fontSettings } from "../fonts";
import _ from "lodash";
import {
  Device,
  Breakpoint,
  MediaQueryRule,
  Roundness,
} from "@mzt-monorepo/mzt-types";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    ${fontSettings()}
  }
`;

const DEVICE_BREAKPOINTS: Record<Device, Breakpoint> = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const getRoundness = (roundness: string, maxRoundness?: string) => {
  switch (roundness) {
    case Roundness.FLAT:
      return "border-radius: 0px;";
    case Roundness.SEMI_ROUND:
      return `border-radius: 8px;`;
    case Roundness.ROUND:
      return `border-radius: ${maxRoundness || "1000px"};`;
  }
};

export const getMediaQueryBreakpoint = (rule: MediaQueryRule, device: Device) =>
  `${rule}: ${_.get(DEVICE_BREAKPOINTS, `[${device}]`)}px`;

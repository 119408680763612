import { Roundness } from "@mzt-monorepo/mzt-types";
import { Moment } from "moment";
import React from "react";
import { Wrapper } from "./styled-components";
import { ModalDateInput } from "../ModalDateInput";

interface ModalTimesheetDate {
  fromDate: Moment | null;
  fromDateChange: React.ChangeEventHandler<HTMLInputElement>;
  toDate: Moment | null;
  toDateChange: React.ChangeEventHandler<HTMLInputElement>;
  min: string;
  max: string;
}

export const ModalTimesheetDate = React.memo(
  ({
    fromDate,
    fromDateChange,
    toDate,
    toDateChange,
    min,
    max,
  }: ModalTimesheetDate) => (
    <Wrapper>
      <ModalDateInput
        id="fromDate"
        value={fromDate ? fromDate.format("YYYY-MM-DD") : ""}
        roundness={Roundness.SEMI_ROUND}
        onChange={fromDateChange}
        min={min}
        max={max}
      />
      <ModalDateInput
        id="toDate"
        value={toDate ? toDate.format("YYYY-MM-DD") : ""}
        roundness={Roundness.SEMI_ROUND}
        onChange={toDateChange}
        min={min}
        max={max}
      />
    </Wrapper>
  )
);

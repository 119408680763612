import React from "react";
import { Switch, Route } from "react-router-dom";
import Protected from "../app/_pages/Protected";
import { ROUTES } from "./routes";
import { RouteType } from "./types";



export const renderRoutes = () => (
  <Switch>
    {ROUTES.map(
      (
        { path, component: Component, exact, isProtected }: RouteType,
        index: number
      ) => (
        <Route exact={exact} path={path} key={index}>
          {isProtected ? (
            <Protected>
              <Component />
            </Protected>
          ) : (
            <Component />
          )}
        </Route>
      )
    )}
  </Switch>
);

import { Moment } from "moment";
import React from "react";
import { Container } from "./styled-components";
import { Calendar } from "@mzt-monorepo/mzt-atomic";
import { cloneMoment } from "@mzt-monorepo/mzt-utils";

interface VacationCalendarMultipleDisplayProps {
  currentDate: Moment;
}

export const VacationCalendarMultipleDisplay = ({
  currentDate,
}: VacationCalendarMultipleDisplayProps) => (
  <Container>
    <Calendar viewOnly mini value={cloneMoment(currentDate, -2, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, -1, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate)} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, 1, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, 2, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, 3, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, 4, "M")} />
    <Calendar viewOnly mini value={cloneMoment(currentDate, 5, "M")} />
  </Container>
);

export default VacationCalendarMultipleDisplay;

const USERS: MockUser[] = [
  {
    displayName: "Tiago Ferreira",
    fullName: "Tiago Miguel Nunes Ferreira",
    department: "IT",
    email: "tiago.ferreira@mozantech.com",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    birthDate: "07/11/1992",
    employeeNo: 1,
    experienceYears: 2,
    phoneNumber: "+351 911 111 111",
    position: "Senior FE Developer && Advisor",
    role: "Consultant",
    startDate: "06/05/2020",
  },
  {
    displayName: "Bruno Guedes",
    fullName: "Bruno Manuel António Guedes",
    department: "IT",
    email: "bruno.guedes@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQHCOL3EqCLwBw/profile-displayphoto-shrink_800_800/0/1585906710187?e=1614816000&v=beta&t=j6G8yZGRFwQox3hoUJfbGlec6bqMQus21kVVmYT1uRI",
    birthDate: "01/01/2000",
    employeeNo: 1,
    experienceYears: 2,
    phoneNumber: "+351 911 111 111",
    position: "CTO",
    role: "Consultant",
    startDate: "16/02/2020",
  },
  {
    displayName: "Pedro Serpa Pinto",
    fullName: "Pedro Miguel Fráguas da Cunha Serpa Pinto",
    department: "Administration",
    email: "pedro.pinto@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQFCYdUquEpRHw/profile-displayphoto-shrink_800_800/0/1605001807012?e=1614816000&v=beta&t=4-7AQzdgpBYMiyVJlCW6PWARoLI9kNqXN2goQl3QOmY",
    birthDate: "19/05/1992",
    employeeNo: 0,
    experienceYears: 3,
    phoneNumber: "+351 911 111 111",
    position: "CEO",
    role: "Backoffice",
    startDate: "01/09/2020",
  },
  {
    displayName: "Mauro Magalhães",
    fullName: "Mauro Miguel Vieira Magalhães",
    department: "Administration",
    email: "mauro.magalhaes@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C5603AQEGw8-okPWmww/profile-displayphoto-shrink_800_800/0/1526320118567?e=1614816000&v=beta&t=wbnGN7lH0GiWH8hdo4c7ctSf6-pgKEvjKUaNoT0UaAI",
    birthDate: "07/10/1991",
    employeeNo: 0,
    experienceYears: 3,
    phoneNumber: "+351 911 111 111",
    position: "CEO",
    role: "Backoffice",
    startDate: "01/09/2020",
  },
  {
    displayName: "Flabio Filho",
    fullName: "Flabio Manuel António Filho",
    department: "IT",
    email: "flabio.filho@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4E03AQE_VkxoHPyCeA/profile-displayphoto-shrink_800_800/0/1602378704055?e=1614816000&v=beta&t=clBWziZ2ktYIRW2gqyb00hcWJ7bVmw-_avl4qz0_De0",
    birthDate: "09/09/1994",
    employeeNo: 1,
    experienceYears: 2,
    phoneNumber: "+351 911 111 111",
    position: "Solution Architect Advisor",
    role: "Backoffice",
    startDate: "01/09/2020",
  },
  {
    displayName: "Rita Oliveira",
    fullName: "Rita Manuel António Oliveira",
    department: "Human Resources",
    email: "rita.oliveira@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQFGmHw4i9DAkQ/profile-displayphoto-shrink_800_800/0/1605988227190?e=1614816000&v=beta&t=twZQWQCLcZvPccCAQizEhXQiQdKzjPj99grzB2dB5KA",
    birthDate: "01/01/2000",
    employeeNo: 1,
    experienceYears: 2,
    phoneNumber: "+351 911 111 111",
    position: "Head of Human Resources",
    role: "Backoffice",
    startDate: "01/09/2020",
  },
  {
    displayName: "Bernardo Cottim Dias",
    fullName: "Bernardo João Cottim Dias",
    department: "Marketing",
    email: "bernardo.dias@mozantech.com",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQEMTzKP9lSa-w/profile-displayphoto-shrink_800_800/0/1562085306558?e=1614816000&v=beta&t=Mw1pBhAh9lLePKE13aywRFQi39MJ7qwGkEH7XZHHS_o",
    birthDate: "01/01/2000",
    employeeNo: 1,
    experienceYears: 2,
    phoneNumber: "+351 911 111 111",
    position: "CMO",
    role: "Backoffice",
    startDate: "01/09/2020",
  },
];

const RESPONSE: MockResponse<MockUser[]> = {
  success: true,
  data: USERS,
};

export const getUsers: () => Promise<MockResponse<MockUser[]>> = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(RESPONSE);
    }, 1500);
  });

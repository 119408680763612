import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  p {
    margin: 0;

    &:first-child {
      margin-top: 16px;
    }
  }
`;

export const Day = styled.h1`
  min-height: 77px;
  font-size: 64px;
  font-weight: normal;
  margin: 0;
  margin-right: 24px;
`;

export const DayOfWeek = styled.p`
  color: ${getColor("darkBlue", 30)};
`;

import { ColorScheme } from "@mzt-monorepo/mzt-types";
import styled, { css } from "styled-components";
import { generateColorScheme, getColor } from "../../../_static/colors";
import { Cross } from "../../../_static/icons";
import { getRoundness } from "../../../_static/styles";
import { VisualStylingProps as StylingProps } from "../types";

const computeWrapperRules = ({
  colorScheme,
  $expanded,
  roundness,
}: StylingProps) => css`
  max-height: ${$expanded ? "320px" : "0"};
  visibility: ${$expanded ? "visible" : "hidden"};
  ${getRoundness(roundness, "20px")}
  ${generateColorScheme(colorScheme)}
`;

export const Item = styled.li`
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  cursor: pointer;
  list-style-type: none;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${getColor("black", 25)};
  }
`;

export const Wrapper = styled.div<StylingProps>`
  box-sizing: border-box;
  transition: all 400ms ease-in-out;
  overflow: hidden;

  position: absolute;
  top: 40px;
  margin-top: 8px;
  width: 100%;
  max-height: 0px;
  visibility: hidden;
  text-overflow: ellipsis;
  z-index: 1;

  border: solid 1px;

  display: flex;
  flex-direction: column;

  ${computeWrapperRules}
`;

export const ScrollableContainer = styled.ul<StylingProps>`
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

export const ContainerDeleteIcon = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 100%;
`;

export const DeleteIcon = styled(Cross)<ColorScheme>`
  height: 100%;
  cursor: pointer;
  fill: ${({ colorScheme }) => colorScheme.secondary};
`;

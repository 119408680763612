import { getRandomInt } from "@mzt-monorepo/mzt-utils";

const USERS: MockTimesheetUser[] = [
  {
    ID: 0,
    displayName: "Tiago Ferreira",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
  {
    ID: 1,
    displayName: "Bruno Guedes",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQHCOL3EqCLwBw/profile-displayphoto-shrink_800_800/0/1585906710187?e=1614816000&v=beta&t=j6G8yZGRFwQox3hoUJfbGlec6bqMQus21kVVmYT1uRI",
    phoneNumber: "+351 911 111 111",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Medical",
    document: [],
    manager: "",
    email: "bruno.guedes@mozantech.com",
  },
  {
    ID: 2,
    displayName: "Pedro Serpa Pinto",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQFCYdUquEpRHw/profile-displayphoto-shrink_800_800/0/1605001807012?e=1614816000&v=beta&t=4-7AQzdgpBYMiyVJlCW6PWARoLI9kNqXN2goQl3QOmY",
    phoneNumber: "+351 911 111 111",
    role: "Backoffice",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#"],
    manager: "",
    email: "pedro.pinto@mozantech.com",
  },
  {
    ID: 3,
    displayName: "Mauro Magalhães",
    picture:
      "https://media-exp1.licdn.com/dms/image/C5603AQEGw8-okPWmww/profile-displayphoto-shrink_800_800/0/1526320118567?e=1614816000&v=beta&t=wbnGN7lH0GiWH8hdo4c7ctSf6-pgKEvjKUaNoT0UaAI",
    phoneNumber: "+351 911 111 111",
    role: "Backoffice",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#"],
    manager: "Pedro Pinto",
    email: "mauro.magalhaes@mozantech.com",
  },
  {
    ID: 4,
    displayName: "Flabio Filho",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4E03AQE_VkxoHPyCeA/profile-displayphoto-shrink_800_800/0/1602378704055?e=1614816000&v=beta&t=clBWziZ2ktYIRW2gqyb00hcWJ7bVmw-_avl4qz0_De0",
    phoneNumber: "+351 911 111 111",
    role: "Backoffice",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Vacations",
    document: ["#", "#", "#", "#", "#", "#"],
    manager: "Mauro Magalhães",
    email: "flabio.filho@mozantech.com",
  },
  {
    ID: 5,
    displayName: "Rita Oliveira",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQFGmHw4i9DAkQ/profile-displayphoto-shrink_800_800/0/1605988227190?e=1614816000&v=beta&t=twZQWQCLcZvPccCAQizEhXQiQdKzjPj99grzB2dB5KA",
    phoneNumber: "+351 911 111 111",
    email: "rita.oliveira@mozantech.com",
    role: "Backoffice",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "-",
    document: [],
    manager: "Pedro Pinto",
  },
  {
    ID: 6,
    displayName: "Bernardo Cottim Dias",
    picture:
      "https://media-exp1.licdn.com/dms/image/C4D03AQEMTzKP9lSa-w/profile-displayphoto-shrink_800_800/0/1562085306558?e=1614816000&v=beta&t=Mw1pBhAh9lLePKE13aywRFQi39MJ7qwGkEH7XZHHS_o",
    phoneNumber: "+351 911 111 111",
    role: "Backoffice",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: [],
    manager: "Pedro Pinto",
    email: "bernardo.dias@mozantech.com",
  },
  {
    ID: 7,
    displayName: "Tiago Ferreira1",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
  {
    ID: 8,
    displayName: "Tiago Ferreira2",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
  {
    ID: 9,
    displayName: "Tiago Ferreira3",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
  {
    ID: 10,
    displayName: "Tiago Ferreira4",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
  {
    ID: 11,
    displayName: "Tiago Ferreira5",
    picture:
      "https://media.vanityfair.com/photos/5f4d3a7e1e10df7a77868a63/master/w_2560%2Cc_limit/BradPitt-GettyImages-1158782727.jpg",
    phoneNumber: "+351 911 111 111",
    email: "tiago.ferreira@mozantech.com",
    role: "Consultant",
    workDays: getRandomInt(28),
    leaveDays: getRandomInt(15),
    reason: "Yes",
    document: ["#", "#"],
    manager: "Pedro Pinto",
  },
];

const RESPONSE: MockResponse<MockTimesheetUser[]> = {
  success: true,
  data: USERS,
};

export const getTimesheets: () => Promise<
  MockResponse<MockTimesheetUser[]>
> = () =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(RESPONSE);
    }, 1500);
  });

import { Moment } from "moment";
import React, { useCallback, useState } from "react";
import {
  DismissSelection,
  VacationDay,
  VacationSelected,
  Wrapper,
} from "./styled-components";

interface VacationCalendarProps {
  fromDate: Moment;
  toDate: Moment;
  setDateInterval: (day: Moment) => void;
  isEditing: boolean;
  changeCurrentDate: (day: Moment) => void;
  currentDate: Moment;
}

const onDayRender = (
  day: Moment,
  setDateInterval: (day: Moment) => void,
  isEditing: boolean,
  fromDate: Moment,
  toDate: Moment,
  hoveredDate: Moment,
  onHover: (day: Moment) => void
) => (
  <>
    {day.isSame(toDate) && isEditing && (
      <DismissSelection onClick={() => setDateInterval(null)} />
    )}
    <VacationDay
      $isEditing={isEditing}
      onClick={() => setDateInterval(day)}
      $hasFullInterval={toDate && fromDate && true}
      $selected={
        day.isSame(fromDate)
          ? VacationSelected.FROM
          : day.isSame(toDate)
          ? VacationSelected.TO
          : VacationSelected.NONE
      }
      $isBetween={day.isBetween(fromDate, toDate || hoveredDate)}
      onMouseEnter={() => onHover(day)}
    >
      {day.format("DD")}
    </VacationDay>
  </>
);

export const VacationCalendar = ({
  fromDate,
  toDate,
  setDateInterval,
  isEditing,
  currentDate,
  changeCurrentDate,
}: VacationCalendarProps) => {
  const [hoveredDate, setHoveredDate] = useState<Moment | null>(null);

  const onHover = useCallback(
    (day: Moment) => {
      if (isEditing && fromDate && !toDate) {
        setHoveredDate(day);
      }
    },
    [isEditing, fromDate, toDate]
  );

  return (
    <Wrapper
      value={currentDate}
      setValue={changeCurrentDate}
      onDayRender={(day: Moment) =>
        onDayRender(
          day,
          setDateInterval,
          isEditing,
          fromDate,
          toDate,
          hoveredDate,
          onHover
        )
      }
    />
  );
};

export default React.memo(VacationCalendar);

import styled from "styled-components";
import { getColor } from "../../_static/colors";

export type TagVariant =
  | "primary"
  | "secondary"
  | "alternative"
  | "success"
  | "alert"
  | "danger"
  | "info";

interface WrapperProps {
  $variant: TagVariant;
  $light: boolean;
}

const getVariantColor = (variant: TagVariant) => {
  switch (variant) {
    case "primary":
      return getColor("purple");
    case "secondary":
      return getColor("orange");
    case "alternative":
      return getColor("violet");
    case "success":
      return getColor("green");
    case "alert":
      return getColor("yellow");
    case "danger":
      return getColor("red");
    case "info":
      return getColor("blue");
    default:
      return getColor("purple");
  }
};

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ $variant, $light }) =>
    `${getVariantColor($variant)}${$light ? "40" : ""}`};
  color: ${({ $variant, $light }) =>
    $light ? getVariantColor($variant) : getColor("white")};
  font-size: 10px;
  width: 80px;
  padding: 2px 4px;
  border-radius: 1rem;
  text-align: center;
  margin: 4px;
`;

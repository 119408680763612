import { ColorScheme, Roundness } from '@mzt-monorepo/mzt-types';
import React from 'react';
import { DEFAULT_COLOR_SCHEME } from '../../../_static/colors';
import {
  Wrapper,
  SearchInput,
  SearchIcon,
  CancelIcon,
} from './styled-components';

interface StylingProps {
  roundness?: Roundness;
  borderless?: boolean;
  colorScheme?: ColorScheme;
}

export interface SearchProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    StylingProps {
  value?: string;
  cancelSearch?: () => void;
}

export const Search = ({
  roundness = Roundness.FLAT,
  borderless = false,
  colorScheme = DEFAULT_COLOR_SCHEME,

  value = '',
  cancelSearch,
  ...props
}: SearchProps) => (
  <Wrapper
    roundness={roundness}
    borderless={borderless}
    colorScheme={colorScheme}
  >
    <SearchIcon />
    <SearchInput {...props} value={value} />
    {cancelSearch && <CancelIcon onClick={cancelSearch} />}
  </Wrapper>
);

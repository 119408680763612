import React from "react";
import { Wrapper, Document, Expand } from "./styled-components";

interface TimesheetDocumentsProps {
  links: string[];
  clickCallback: (link: string) => void;
  expandCallback: () => void;
}

const renderLinks = (links: string[], cb: (link: string) => void) =>
  [...links]
    .splice(0, 3)
    .map((link, index) => (
      <Document onClick={() => cb(link)} key={`${link}_${index}`} />
    ));

const renderExpand = (render: boolean, cb: () => void) =>
  render ? <Expand onClick={cb}>...</Expand> : null;

export const TimesheetDocuments = ({
  links,
  clickCallback,
  expandCallback,
}: TimesheetDocumentsProps) => (
  <Wrapper>
    {renderLinks(links, clickCallback)}
    {renderExpand(links.length > 3, expandCallback)}
  </Wrapper>
);

export default React.memo(TimesheetDocuments);

import { useState, useCallback, useEffect } from "react";
import {
  TableData,
  UseTableSelectionReturn,
  UseTableSelectionState as State,
} from "@mzt-monorepo/mzt-types";

//SELECTION HOOK
export const useTableSelection = (
  rows: TableData[]
): UseTableSelectionReturn => {
  const [state, setState] = useState<State>({});

  const checkAll = useCallback(
    (state: boolean) => {
      setState(
        rows.reduce((acc, row) => {
          acc[row.key] = row.isSelected || state || false;
          return acc;
        }, {})
      );
    },
    [rows]
  );

  const set = useCallback((row: TableData, nextState: boolean) => {
    setState((prevState) => {
      const newState = { ...prevState };
      newState[row.key] = nextState;
      return newState;
    });
  }, []);

  const reset = useCallback(() => {
    checkAll(false);
  }, [checkAll]);

  useEffect(() => {
    reset();
  }, [rows, reset]);

  return { state, set, reset, checkAll };
};

import styled, { css } from "styled-components";
import { getColor } from "../../_static/colors";
import { ButtonVariant } from ".";
import { ButtonHTMLAttributes } from "react";
import { Roundness } from "@mzt-monorepo/mzt-types";
import { getRoundness } from "../../_static/styles";

interface StyleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  isHollow: boolean;
  roundness: Roundness;
}

const getVariantColor = (variant: ButtonVariant) => {
  switch (variant) {
    case "primary":
      return getColor("orange");
    case "secondary":
      return getColor("darkBlue");
    default:
      return getColor("orange");
  }
};

const applyPropsStyle = ({
  isHollow,
  variant,
  roundness,
  disabled,
}: StyleProps) => css`
  border: ${isHollow ? `3px solid ${getVariantColor(variant)}` : "none"};
  background-color: ${isHollow ? "transparent" : getVariantColor(variant)};
  color: ${isHollow ? getVariantColor(variant) : getColor("white")};
  opacity: ${disabled ? "0.2" : "1"};
  cursor: ${disabled ? "not-allowed" : "pointer"};
  ${getRoundness(roundness)}
`;

export const Wrapper = styled.button<StyleProps>`
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  height: 40px;
  width: 120px;
  padding: 8px 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  text-transform: uppercase;
  ${applyPropsStyle}
`;

import React from "react";
import { getColor } from "../../_static/colors";
import { Left, Clickable, Right, Wrapper } from "./styled-components";
import { PaginationConfig } from "@mzt-monorepo/mzt-types";

interface PaginationProps {
  currPage: number;
  numberOfPages: number;
  handlePage: (goToPage: number) => void;
  pagination: PaginationConfig;
}

/**
 * Renders all the possible choices of pages
 * @param currPage Current page we're at
 * @param numberOfPages Number of pages we have
 * @param handlePage Function that receives the destination page
 */
const printPages = (
  currPage: number,
  numberOfPages: number,
  handlePage: (goToPage: number) => void
) =>
  Array.from(Array(numberOfPages), (_: unknown, value: number) => (
    <Clickable
      aria-label={`Page number ${value + 1}`}
      $isCurrent={value === currPage}
      onClick={() => handlePage(value)}
      key={value}
    >
      {value + 1}
    </Clickable>
  ));

export const Pagination = ({
  currPage,
  numberOfPages,
  handlePage,
  pagination,
}: PaginationProps) => (
  <Wrapper
    $isPaginationOnTop={pagination.position === "top"}
    color={pagination.color ? pagination.color : getColor("darkBlue")}
  >
    <Clickable
      disabled={currPage === 0}
      onClick={() => handlePage(currPage - 1)}
      aria-label="Previous page"
    >
      <Left />
      Previous
    </Clickable>
    {printPages(currPage, numberOfPages, handlePage)}
    <Clickable
      disabled={currPage === numberOfPages - 1}
      onClick={() => handlePage(currPage + 1)}
      aria-label="Next page"
    >
      Next
      <Right />
    </Clickable>
  </Wrapper>
);

export default React.memo(Pagination);

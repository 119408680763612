import styled from "styled-components";
import { injectBackground } from "../styles/generators";

interface Props {
  hasBackground?: boolean;
}

const Main = styled.main<Props>`
  ${({ hasBackground }) => injectBackground(hasBackground)}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px 93px;
`;

export default Main;

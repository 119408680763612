import { Avatar } from "@mzt-monorepo/mzt-atomic";
import { zeroPad } from "@mzt-monorepo/mzt-utils";
import React from "react";
import {
  Container,
  GrayText,
  Header,
  Tag,
  AvatarSetting,
  EditAvatar,
  Fullname,
  PersonalInfo,
  RoleTag,
  Documents,
  DocumentsRow,
  DocumentBlock,
  Document,
} from "./styled-components";

interface Props {
  user: MockUser;
}

const handleEditAvatar = (event: MouseEvent) => {
  event.stopPropagation();
  alert("Edit user avatar");
};

const handleOpenFile = (event: MouseEvent) => {
  event.stopPropagation();
  alert("Opened file");
};

const EmployeeCard = ({ user }: Props) => (
  <Container>
    <Header>
      <Tag>{user.role}</Tag>
      <GrayText>{`E/N: ${zeroPad(user.employeeNo, 8)}`}</GrayText>
    </Header>
    <AvatarSetting>
      <Avatar src={user.picture} />
      <EditAvatar onClick={handleEditAvatar} />
    </AvatarSetting>
    <Fullname>{user.fullName}</Fullname>
    <PersonalInfo>
      <GrayText>{user.email}</GrayText>
      <GrayText>{user.phoneNumber}</GrayText>
      <GrayText>Date of Birth: {user.birthDate}</GrayText>
    </PersonalInfo>
    <RoleTag>
      <p>{user.position}</p> | <p>Exp: {user.experienceYears}</p>
    </RoleTag>
    <Documents>
      <DocumentsRow>
        <h5>Documents:</h5>
      </DocumentsRow>
      <DocumentsRow>
        <DocumentBlock>
          <h5>Equipment:</h5>
          <Document onClick={handleOpenFile} />
        </DocumentBlock>
        <DocumentBlock>
          <h5>Financial Proposal:</h5>
          <Document onClick={handleOpenFile} />
        </DocumentBlock>
        <DocumentBlock>
          <h5>Image Rights:</h5>
          <Document onClick={handleOpenFile} />
        </DocumentBlock>
      </DocumentsRow>
    </Documents>
  </Container>
);

export default EmployeeCard;

import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Home = (props: Record<string, any>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={44}
    height={44}
    viewBox="0 0 44 44"
    {...props}
  >
    <path d="M0 0h44v44H0z" fill="none" />
    <path
      d="M18.334 36.667v-11h7.333v11h9.167V22h5.5L22 5.5 3.667 22h5.5v14.667z"
      fill="#fa6f39"
    />
  </svg>
);

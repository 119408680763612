import React from "react";
import AddVacationButton from "../../_atoms/AddVacationButton";
import VacationCalendarTags from "../VacationCalendarTags";
import { Container } from "./styled-components";

export interface VacationCalendarFooterProps {
  handleClick: (isEditing: boolean) => void;
}

export const VacationCalendarFooter = ({
  handleClick,
}: VacationCalendarFooterProps) => (
  <Container>
    <VacationCalendarTags />
    <AddVacationButton handleClick={handleClick} />
  </Container>
);

export default VacationCalendarFooter;

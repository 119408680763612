import {
  SortConfig,
  SortDirection,
  TableColumn,
  UseTableSelectionReturn,
} from "@mzt-monorepo/mzt-types";
import React from "react";
import { Checkbox } from "../../../_atoms/checkbox";
import { Wrapper, Item, SelectItem, Sort, Head } from "./styled-components";

interface TableHeaderProps {
  columns: TableColumn[];
  sort: string | SortConfig;
  handleSortDirection: (
    index: number,
    direction: SortDirection | undefined
  ) => void;
  selectable: UseTableSelectionReturn;
  handleSelectAllRows: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
}

const conditionalRenderArrow = (
  col: string,
  sort: string | SortConfig,
  direction: SortDirection
) => {
  // If the type of the sort variable is a string, it means it is only sorting
  if (typeof sort === "string" || !sort.cols.includes(col)) return null;

  return <Sort direction={direction} />;
};

const renderColumns = (
  columns: TableColumn[],
  sort: string | SortConfig,
  handleSortDirection: (
    index: number,
    direction: SortDirection | undefined
  ) => void
) =>
  columns.map((col: TableColumn) => {
    const index = typeof sort !== "string" && sort.cols.indexOf(col.dataIndex);
    const direction = typeof sort !== "string" && sort.directions[index];
    const reverse = direction === "asc" ? "desc" : "asc";

    return (
      <Item
        key={col.key}
        onClick={() => handleSortDirection(index, reverse)}
        $hasSortingFeature={
          typeof sort !== "string" && sort.cols.includes(col.dataIndex)
        }
        alignment={col.alignment}
        size={col.size}
      >
        <p>{col.title}</p>
        {conditionalRenderArrow(col.dataIndex, sort, reverse)}
      </Item>
    );
  });

export const TableHeader = ({
  columns,
  sort,
  handleSortDirection,
  selectable,
  handleSelectAllRows,
  isSelected,
}: TableHeaderProps) => (
  <Head>
    <Wrapper>
      {selectable && (
        <SelectItem $hasSortingFeature={false}>
          <Checkbox checked={isSelected} onChange={handleSelectAllRows} />
        </SelectItem>
      )}
      {renderColumns(columns, sort, handleSortDirection)}
    </Wrapper>
  </Head>
);

export default React.memo(TableHeader);

import React from "react";
import { useSelector } from "react-redux";
import { Container } from "./styled-components";
import { getUser } from "./../../../redux/selectors/auth";

const Home = () => {
  const user = useSelector(getUser);

  return (
    <Container hasBackground>
      <h1>WELCOME {user.displayName.toUpperCase()}</h1>
    </Container>
  );
};

export default Home;

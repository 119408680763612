import styled from "styled-components";
import Button from "../../button";
import { File, CrossThin } from "../../../_static/icons";

interface FileButtonProps {
  small?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const FileButton = styled(Button)<FileButtonProps>`
  width: 60%;
  font-weight: 300;
  text-transform: initial;
  margin-top: 8px;
  border-width: 2px;
  ${({ small }) =>
    small &&
    `
    transform: scale(0.5);
  `}
`;

export const ListWrapper = styled.ul`
  padding: 16px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  list-style-type: none;
  margin: 8px 0;
`;

export const ListText = styled.span`
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
`;

export const ListIcon = styled(File)`
  height: 32px;
`;

export const ListDelete = styled(CrossThin)`
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

import React from "react";
import { Wrapper } from "./styled-components";

export interface AddVacationButtonProps {
  handleClick: (isEditing: boolean) => void;
}

export const AddVacationButton = ({ handleClick }: AddVacationButtonProps) => (
  <Wrapper onClick={() => handleClick(true)}>+</Wrapper>
);

export default AddVacationButton;

import React from "react";
import { Wrapper, Row, Divider } from "./styled-component";

interface VacationDaysBalanceProps {
  currYearBalance: number;
  lastYearBalance: number;
  annualAllowance: number;
}

export const VacationDaysBalance = ({
  currYearBalance,
  lastYearBalance,
  annualAllowance,
}: VacationDaysBalanceProps) => (
  <Wrapper>
    <Row>
      <span>
        Current year balance: <b>{currYearBalance} day(s)</b>
      </span>
      <Divider> | </Divider>
      <span>
        Balance from last year: <b>{lastYearBalance} day(s)</b>
      </span>
    </Row>
    <Row>
      <span>
        Annual allowance: <b>{annualAllowance} day(s)</b>
      </span>
    </Row>
  </Wrapper>
);

export default VacationDaysBalance;

import styled from "styled-components";

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

export const Name = styled.p`
  margin: 0;
  font-size: 14px;
`;

export const Email = styled.p`
  margin: 0;
  font-size: 12px;
`;

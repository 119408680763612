import React, { useCallback, useState } from "react";
import MonthScrollerTab from "../../_atoms/MonthScrollerTab";
import { Moment } from "moment";
import { Container, Up, Down } from "./styled-components";
import { cloneMoment } from "@mzt-monorepo/mzt-utils";

export interface MonthScrollerProps {
  changeCurrentDate: (day: Moment) => void;
  currentDate: Moment;
}

export const MonthScroller = ({
  changeCurrentDate,
  currentDate,
}: MonthScrollerProps) => {
  const [index, setIndex] = useState<number>(0);

  const addMonth = useCallback(() => {
    setIndex(index + 1);
  }, [setIndex, index]);

  const subtractMonth = useCallback(() => {
    setIndex(index - 1);
  }, [setIndex, index]);

  const selectDate = useCallback(
    (date: Moment) => {
      changeCurrentDate(date);
      setIndex(0);
    },
    [changeCurrentDate, setIndex]
  );

  return (
    <Container>
      <Up onClick={() => subtractMonth()} />
      <MonthScrollerTab
        date={cloneMoment(currentDate, index - 2, "M")}
        onClick={selectDate}
      />
      <MonthScrollerTab
        date={cloneMoment(currentDate, index - 1, "M")}
        onClick={selectDate}
      />
      <MonthScrollerTab
        date={cloneMoment(currentDate, index, "M")}
        isSelected
        onClick={selectDate}
      />
      <MonthScrollerTab
        date={cloneMoment(currentDate, index + 1, "M")}
        onClick={selectDate}
      />
      <MonthScrollerTab
        date={cloneMoment(currentDate, index + 2, "M")}
        onClick={selectDate}
      />
      <Down onClick={() => addMonth()} />
    </Container>
  );
};

export default React.memo(MonthScroller);

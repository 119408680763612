import React from "react";
import { Wrapper } from "./styled-components";

export type CardVariant = "primary" | "secondary" | "alternative";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: CardVariant;
}

export const Card = ({
  children,
  variant = "primary",
  ...props
}: CardProps) => (
  <Wrapper {...props} variant={variant} role="application">
    {children}
  </Wrapper>
);

export default Card;

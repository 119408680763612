import { File, getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  * {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  svg {
    width: 24px;
  }
`;

export const Document = styled(File)`
  fill: ${getColor("darkBlue")};
`;

export const Expand = styled.button`
  background: none;
  padding: inherit;
  margin: inherit;
  width: initial;
  font-size: 24px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

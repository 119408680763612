import React, { JSXElementConstructor } from "react";

export type SvgType =
  | "account-balance"
  | "analytics"
  | "arrow"
  | "business"
  | "cross"
  | "emoji-objects"
  | "feedback"
  | "file"
  | "flight-takeoff"
  | "groups"
  | "help-outline"
  | "loading"
  | "payments"
  | "school"
  | "timesheets";

export const iconsPath: Record<string, JSX.Element[]> = {
  "account-balance": [
    <rect
      className="a"
      width="6"
      height="13"
      transform="translate(3.333 16)"
    />,
    <rect
      className="a"
      width="5.5"
      height="12.833"
      transform="translate(15.583 16.5)"
    />,
    <rect
      className="a"
      width="36"
      height="6"
      transform="translate(0.333 33)"
    />,
    <rect
      className="a"
      width="6"
      height="13"
      transform="translate(27.333 16)"
    />,
    <path
      className="a"
      d="M20.333,1,2,10.167v3.667H38.667V10.167Z"
      transform="translate(-2 -1)"
    />,
  ],
  // 'emoji-objects': 'emoji-objects',
  // 'flight-takeoff': 'flight-takeoff',
  // 'help-outline': 'help-outline',
  analytics: [
    <path className="a" d="M0,0H44V44H0Z" fill="none" />,
    <path
      className="b"
      d="M36.778,3H7.222A4.235,4.235,0,0,0,3,7.222V36.778A4.235,4.235,0,0,0,7.222,41H36.778A4.235,4.235,0,0,0,41,36.778V7.222A4.235,4.235,0,0,0,36.778,3ZM15.667,32.556H11.444V22h4.222Zm8.444,0H19.889V26.222h4.222Zm0-10.556H19.889V17.778h4.222Zm8.444,10.556H28.333V11.444h4.222Z"
      transform="translate(0 0)"
    />,
  ],
  arrow: [
    <path
      d="M629.78 212.98C636.37 206.39 640 197.6 640 188.22C640 178.85 636.37 170.05 629.78 163.47C627.68 161.37 610.9 144.59 608.81 142.49C595.14 128.84 572.94 128.84 559.29 142.49C543.35 158.44 463.63 238.16 320.13 381.65C176.48 238 96.67 158.19 80.71 142.23C74.11 135.64 65.33 132 55.96 132C46.57 132 37.79 135.64 31.19 142.23C29.09 144.32 12.32 161.1 10.22 163.2C3.63 169.79 0 178.58 0 187.96C0 197.33 3.63 206.13 10.22 212.71C38.73 241.22 266.78 469.28 295.29 497.79C301.9 504.39 310.73 508.02 320.12 508C329.54 508.02 338.36 504.39 344.97 497.79C401.93 440.83 601.3 241.46 629.78 212.98Z"
      id="bX0zJOMJh"
    ></path>,
    <use
      xlinkHref="#bX0zJOMJh"
      opacity="1"
      fill="#000000"
      fill-opacity="1"
    ></use>,
    <use
      xlinkHref="#bX0zJOMJh"
      opacity="1"
      fill-opacity="0"
      stroke="#000000"
      stroke-width="1"
      stroke-opacity="0"
    ></use>,
  ],
  // business: 'business',
  // cross: 'cross',
  // feedback: 'feedback',
  // file: 'file',
  // groups: 'groups',
  loading: [
    <circle cx="98" cy="376" fill="#909ba6" r="53" />,
    <circle cx="439" cy="336" fill="#c8d2dc" r="46" />,
    <circle cx="397" cy="112" fill="#e9edf1" r="38" />,
    <ellipse cx="56.245" cy="244.754" fill="#7e8b96" rx="56.245" ry="54.874" />,
    <ellipse
      cx="217.821"
      cy="447.175"
      fill="#a2abb8"
      rx="51.132"
      ry="49.825"
    />,
    <ellipse
      cx="349.229"
      cy="427.873"
      fill="#b9c3cd"
      rx="48.575"
      ry="47.297"
    />,
    <ellipse
      cx="117.092"
      cy="114.794"
      fill="#5f6c75"
      rx="58.801"
      ry="57.397"
    />,
    <ellipse
      cx="453.538"
      cy="216.477"
      fill="#dce6eb"
      rx="43.462"
      ry="42.656"
    />,
    <circle cx="263" cy="62" fill="#4e5a61" r="62" />,
  ],
  // payments: 'payments',
  // school: 'school',
  // timesheets: 'timesheets',
};

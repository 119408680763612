import { getColor } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";
import Background from "../../../assets/triangle_bg.svg";

type DayType = "holiday" | "approved" | "pending";

interface DayProps {
  $type: DayType;
}

interface WrapperProps {
  $isSelected: boolean;
}

const getBackgroundColor = (day: DayType) => {
  switch (day) {
    case "approved":
      return getColor("green", 60);
    case "holiday":
      return getColor("blue", 60);
    case "pending":
      return getColor("yellow", 60);
    default:
      return getColor("blue", 60);
  }
};

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  flex: 1;
  display: flex;
  background-image: ${({ $isSelected }) => $isSelected && `url(${Background})`};
  background-repeat: no-repeat;
  justify-content: center;
  cursor: pointer;
  background-position: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  width: 16rem;
`;

export const Month = styled.div`
  color: ${getColor("darkBlue")};
  background-color: ${getColor("blue", 25)};
  height: 88px;
  width: 88px;
  border-radius: 32px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
`;

export const Days = styled.div`
  display: flex;
  justify-content: center;
`;

export const Day = styled.div<DayProps>`
  margin: 8px;
  background-color: ${({ $type }) => getBackgroundColor($type)};
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 8px;
    height: 8px;
  }
`;

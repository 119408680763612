import React from "react";

import { getColor } from "@mzt-monorepo/mzt-atomic";
import {
  Circle,
  Container,
  StyledContainer,
  LabelDays,
  LabelTypeDays,
} from "./styled-components";

export enum DescriptionOfDays {
  WORKED = "Worked Days",
  VACATIONS = "Vacations",
  MEDICAL = "Medical",
  FAMILY = "Family Care",
}

export interface Props {
  title: DescriptionOfDays;
  numberOfDays: string;
}

// returns the circle color depending on days type
export const computeColor = (days: DescriptionOfDays) => {
  switch (days) {
    case DescriptionOfDays.WORKED:
      return getColor("orange");
    case DescriptionOfDays.VACATIONS:
      return getColor("blue");
    case DescriptionOfDays.MEDICAL:
      return getColor("green");
    case DescriptionOfDays.FAMILY:
      return getColor("violet");
    default:
      return getColor("orange");
  }
};

const TimesheetsCard = (props: Props) => {
  const color = React.useMemo(() => {
    return computeColor(props.title);
  }, [props.title]);

  return (
    <Container>
      <StyledContainer>
        <LabelTypeDays>{props.title}</LabelTypeDays>
        <Circle circleColor={color}>
          {props.numberOfDays}
          <LabelDays>Days</LabelDays>
        </Circle>
      </StyledContainer>
    </Container>
  );
};

export default React.memo(TimesheetsCard);

import { Moment } from "moment";
import React from "react";
import VacationDaysSelectedDate from "../../_atoms/VacationDaysSelectedDate";
import { Wrapper, Title } from "./styled-components";

export interface VacationDaysSelectionProps {
  fromDate: Moment | null;
  toDate: Moment | null;
}

export const VacationDaysSelection = ({
  fromDate,
  toDate,
}: VacationDaysSelectionProps) => (
  <Wrapper>
    <Title>From</Title>
    <VacationDaysSelectedDate date={fromDate} />
    <Title>To</Title>
    <VacationDaysSelectedDate date={toDate} />
  </Wrapper>
);

export default VacationDaysSelection;

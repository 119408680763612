import { css } from "styled-components";
import { getColor } from "../colors";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MonumentRegular from "./MonumentExtended/MonumentExtended-Regular.otf";

export enum Font {
  SEGOEUI = "Segoe UI",
  MONUMENT = "Monument",
}

export const fontSettings = () => css`
  @font-face {
    font-family: "Monument";
    src: url(${MonumentRegular});
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
  }

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: ${getColor("black")};
`;

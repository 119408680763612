import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CrossFlat = (props: Record<string, any>) => (
  <svg
    id="clear-24px"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path id="Path_1535" data-name="Path 1535" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_1536"
      data-name="Path 1536"
      d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z"
      fill="#f7652c"
    />
  </svg>
);

import styled from "styled-components";
import { getColor } from "../../../_static/colors";
import { Expand } from "../../../_static/icons";
import {
  cellStyle,
  ComputeSizeAndAlignment,
  CellProps,
  selectCellStyle,
} from "../styled-components";

interface HeaderCellProps extends CellProps {
  $hasSortingFeature: boolean;
}
interface SortingProps {
  direction: "asc" | "desc";
}

export const Wrapper = styled.tr`
  display: flex;
`;

export const Head = styled.thead`
  box-shadow: 0px 5px 5px -5px ${getColor("black")};
`;

export const Item = styled.td<HeaderCellProps>`
  ${cellStyle}

  ${(props) => ComputeSizeAndAlignment(props)};

  font-weight: normal;
  font-size: 14px;
  cursor: ${({ $hasSortingFeature }) =>
    $hasSortingFeature ? "pointer" : "initial"};

  &:active {
    transform: ${({ $hasSortingFeature }) =>
      $hasSortingFeature && "scale(1.02)"};
  }
`;

export const SelectItem = styled(Item)`
  ${selectCellStyle}
`;

export const Sort = styled(Expand)<SortingProps>`
  width: 24px;
  height: 24px;
  margin: 0 16px;
  transform: rotate(
    ${({ direction }) => (direction === "asc" ? "0deg" : "180deg")}
  );
`;

import { BaseAction } from "../../typescript/redux";
import TYPES from "../types/timesheets";

export interface TimesheetAction extends BaseAction {
  payload?: MockTimesheetUser[];
}

export const start = () => ({
  type: TYPES.START,
});

export const success: (payload: MockTimesheetUser[]) => TimesheetAction = (
  payload: MockTimesheetUser[]
) => ({
  type: TYPES.SUCCESS,
  payload,
});

export const fail = () => ({
  type: TYPES.FAIL,
});

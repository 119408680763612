import { Button } from "@mzt-monorepo/mzt-atomic";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex;
`;

export const RowContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 16px;
`;

export const ItemContainer = styled.div`
  width: 448px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: 64px;

  &:last-child {
    margin-right: 0;
  }
`;

export const ModalTimesheetSubmit = styled(Button)`
  font-weight: 500;
  padding: 4px 16px;
  height: auto;
  width: 82px;
  align-self: flex-end;
  margin-right: 32px;
  margin-bottom: 64px;
  font-size: 12px;
`;

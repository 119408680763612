import React from "react";
import { LinkProps } from "react-router-dom";
import { Container } from "./styled-components";

export interface NavbarTabProps extends LinkProps {
  isActive?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
}

const STYLE = {
  height: 18,
  width: 18,
  margin: "0 24",
};

export const NavbarTab = ({
  to,
  children,
  isActive = false,
  icon: Component,
}: NavbarTabProps) => (
  <Container to={to} $isActive={isActive}>
    <Component style={STYLE} />
    <p>{children}</p>
  </Container>
);

export default NavbarTab;

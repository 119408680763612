import styled from "styled-components";
import { getColor } from "../../../_static/colors";
import {
  CellProps,
  cellStyle,
  ComputeSizeAndAlignment,
  selectCellStyle,
} from "../styled-components";

export interface RowProps {
  $fillBackground?: boolean;
}

export const Wrapper = styled.tr<RowProps>`
  display: flex;
  flex-grow: 1;
  background-color: ${({ $fillBackground }) =>
    $fillBackground && getColor("darkBlue", 10)};
`;

export const Cell = styled.td<CellProps>`
  ${cellStyle}
  ${(props) => ComputeSizeAndAlignment(props)};
`;

export const SelectCell = styled(Cell)`
  ${selectCellStyle}
`;
